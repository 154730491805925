import { NgIf, NgSwitch, NgSwitchCase, NgClass } from '@angular/common';
import { Component } from '@angular/core';

import { BaseComponent } from '@app/base.component';
import { RmModel } from '@core/models/rm-model';
import { PorterService } from '@shared/service/porter/porter.service';
import { CookieComponent } from '../cookie/cookie.component';
import { LoaderComponent } from '../loader/loader.component';
import { ModalComponent } from "../modal/modal.component";
import { environment } from '@env/environment';
import { RMSessionDataKeys } from '@app/utils/rm.utilities';
import { Router } from '@angular/router';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { CookiesService } from '@shared/service/cookie/cookies.service';
const { RM_COOKIE } = RMSessionDataKeys;

@Component({
  selector: 'rm-porter',
  standalone: true,
  templateUrl: './porter.component.html',
    imports: [NgSwitch, NgSwitchCase, NgIf, NgClass, CookieComponent, LoaderComponent, ModalComponent]
})
export class PorterComponent extends BaseComponent {
  modalData!: RmModel.ModalDataState;
  isProd = environment.production;
  showBanner! : boolean;
  excludeBanners = false;

  constructor(private porterService: PorterService, private cookieService: CookiesService, public router: Router) {
    super();

    this.porterService.showModal$.pipe(this.unsubscribeOnComponentDestroyTakeUntil$).subscribe((showModal: RmModel.ModalDataState) => {
      this.modalData = showModal;
      this.showBanner = !!this.cookieService.getCookie(RM_COOKIE.KEY);
      this.excludeBanners = this.router.url.includes(`/${RMRouterLink.DisputeTransaction}`) || this.router.url.startsWith(`/${RMRouterLink.Payment}`);
    });
  }
}
