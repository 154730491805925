export const environment = {
  production: false,

  siteBaseUrl: 'https://uat.reevomoney.com',

  apiBaseUrl: 'https://api-test.mycommunityfinance.co.uk/cuplatform/',
  mosaicBaseUrl: 'https://api-uat.gojoko.tech/',

  // Amplifi Capital API
  apiBaseUrlV2: 'https://api-uat.amplificapital.com/',

  // Loans
  createLoanApplicationAPI: 'loan-application-service/organic-application',
  createQuoteAPI: 'loan-application-service/application',

  addressLoanApplicationAPI: 'loanapplications/address?postcode=',
  jobTitlesAPI: 'employers/occupation',

  // Payments
  validatePaymentTokenAPI: 'loanapplications/validatepaymenttoken',
  bankListAPI: 'loanapplications/pisp/banklist/',
  initiatePayment: 'loanapplications/pisp/initiatePayment',
  paymentStatus: 'loanapplications/pisp/paymentStatus/',
  paymentSuccessUrl: 'payment/success?result=success&paymentInitiationId=',
  paymentFailUrl: 'payment/failure?result=fail&paymentInitiationId=',

  // Dispute
  disputeCreditCardTransactionAPI: 'credit-card-dispute-service/v1/',
  disputeSubmitAPI: 'dispute/',
  disputeS3URLToUploadFile: 'upload/',

  // SF app variables
  disputeSFPostMessageOrigin: '*',
  disputeSubmitPostMsgEventOrigin: 'https://mycommunityfinance--uat.sandbox.lightning.force.com/',

  // Mobile app universal links variable
  disputeJourneyNonsupportExitDeepLink: 'reevo.uat://dispute-transaction/no-support',
  disputeJourneyExitDeepLink: 'reevo.uat://dispute-transaction/submit',

  // API keys
  butterCmsAPIKey: 'cd41ba18fd45ebd05f6150715301da5f1ce9054b',
  rvoDirectProductAPIKey: 'nqR4fvM9qrBTi041rzC9Ee24kEFwXH',
  DIRECT_SEARCH_AGGREGATOR_API_KEY: 'qbFgCmHQGWi8DZiad4gCESfAN2THmZ',

  //External Links
  salesForceHomeUrl: 'https://uat.customer.reevomoney.com',
  declinePanel: 'decline-panel-service/applicationId?',

  // App store
  appleAppStore: 'https://testflight.apple.com/',
  googlePlayStore: 'https://play.google.com/store/apps/details?id=com.reevomoney.uat',

  // Google Analytics
  googleTagManagerUrl: 'https://www.googletagmanager.com/gtag/js?id=G-2JRBEQMWJ0',
  googleTagManagerScript: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NBRWNPF6');`,
};
