import { LoanPurpose } from '@features/loans/enums/loans.enum';
import { EmploymentStatus, ResidentialStatus } from '@core/enums/rm.enum';

export class LoanUtilities {
  static YEARLY_LIST = [
    {
      label: 'Yearly',
      value: 'yearly'
    }
  ];

  static FINANCIAL_DEPENDENT = [
    {
      title: '0',
      id: 'fd1'
    },
    {
      title: '1',
      id: 'fd2'
    },
    {
      title: '2',
      id: 'fd3'
    },
    {
      title: '3+',
      id: 'fd4'
    }
  ];

  static LIVING_SITUATION = [
    {
      label: 'Homeowner with a mortgage',
      value: ResidentialStatus.OwnerWithMortgage
    },
    {
      label: 'Homeowner without a mortgage',
      value: ResidentialStatus.Owner
    },
    {
      label: 'Private tenant',
      value: ResidentialStatus.NotOwner
    },
    {
      label: 'Council tenant',
      value: ResidentialStatus.CouncilTenant
    },
    {
      label: 'Living with relatives or friends',
      value: ResidentialStatus.SocialHousingIndicator
    }
  ];
  static RM_AGGREGATOR = 'rm_aggregator';

  static RM_DOB_MIN_AGE_SAVINGS = 18;
  static RM_DOB_MIN_AGE_LOANS = 21;
  static RM_DOB_MAX_AGE_LOANS = 65;

  static MOVE_YEAR_LIST = [
    {
      label: 'Less than 3 years ago',
      id: 'y1'
    },
    {
      label: 'More than 3 years ago',
      id: 'y2'
    }
  ];

  static RM_EMPLOYMENT_STATUS = [
    {
      label: 'Full-time employed',
      value: EmploymentStatus.FullTimeEmployed
    },
    {
      label: 'Part-time employed',
      value: EmploymentStatus.PartTimeEmployeed
    },
    {
      label: 'Self-employed',
      value: EmploymentStatus.SelfEmployed
    },
    {
      label: 'Business owner',
      value: EmploymentStatus.BusinessOwner
    },
    {
      label: 'Contractor',
      value: EmploymentStatus.Contractor
    },
    {
      label: 'Retired',
      value: EmploymentStatus.Retired
    },
    {
      label: 'Student',
      value: EmploymentStatus.Student
    },
    {
      label: 'Homemaker',
      value: EmploymentStatus.Homemaker
    },
    {
      label: 'Unemployed',
      value: EmploymentStatus.Unemployed
    }
  ];

  static RM_LOANS_JOURNEY_FORM_ERRORS = {
    // Your details
    select_title: 'Please select your title',

    firstNameRequiredErrorMsg: 'Please confirm your first name',
    firstNameMinLenErrorMsg: 'Please enter at least 2 characters',
    firstNamePatternErrorMsg: 'Please enter a valid name',

    lastNameRequiredErrorMsg: 'Please confirm your last name',
    lastNameMinLenErrorMsg: 'Please enter at least 2 characters',
    lastNamePatternErrorMsg: 'Please enter valid name',

    date: 'Please enter a valid day',
    month: 'Please enter a valid month',
    year: 'Please enter a valid year',
    invalidDOB: 'Please enter a valid dob, like 01/02/1990',
    dobAgeAllowedErrorMsgSavings: 'You must be over 18 years old to apply',
    dobAgeAllowedErrorMsg: 'We can only accept applicants who are between 21 and 65 years old',

    email_required: 'Please enter your email address',
    email_pattern: 'Enter an email address in the correct format, like name@example.com',

    mobileNumRequiredErrorMsg: 'Please enter your UK mobile number',
    mobileNumPatternErrorMsg: 'Please enter a valid UK mobile number',

    // Your address
    current_postCode: 'Please enter your postcode and click the button to find your address',
    valid_current_postCode: 'Please enter a valid UK postcode',
    findAddressBtnErrorMsg: 'Please click the Find my address button to get address list',
    check_full_address: 'Please check your full address',
    invalid_address_response: 'No address found matching your postcode',
    current_address: 'Please select your address from the list',
    current_move_in_year: 'Please select when you moved in to this address',
    outside_of_uk: 'Please select I lived outside the UK',
    flat_building_name_number: 'Please enter either the flat, building number or building name',
    town_city_name: 'Please enter the town or city',

    // Your employment
    employment_status: 'Please select what describes you best',
    employment_title: 'Please select an option from the list',
    employerNameErrorMsg: 'Please enter your company’s name',
    employerNameMinLenErrorMsg: 'Please enter at least 2 characters',
    taxResidentErrorMsg: 'Please confirm UK tax residency',
    countryNameErrorMsg: 'Please select country Name',

    //Loan Purpose
    purpose: 'Please select your loan purpose',
    purposeIfOthersErrorMsg: 'Please describe your loan purpose',

    // Your finances
    current_income: 'Please enter your income before tax, including any benefits you get',
    no_of_dependents: 'Please select',
    living_situation: 'Please select what describes you best',
    rent_amount: 'Please enter your monthly rent/mortgage',

    // Your employment
    job_title: 'Please select what describes you best',
    employer_name: 'Please enter your employer name'
  };

  static RM_LOAN_PURPOSE = [
    {
      purpose: 'Car Purchase',
      id: LoanPurpose.CarPurchase
    },
    {
      purpose: 'Home Improvements',
      id: LoanPurpose.HomeImprovement
    },
    {
      purpose: 'Debt Consolidation',
      id: LoanPurpose.DebtConsolidation
    },
    {
      purpose: 'Living Expenses',
      id: LoanPurpose.LivingExpenses
    },
    {
      purpose: 'Holiday',
      id: LoanPurpose.Holiday
    },
    {
      purpose: 'Other',
      id: LoanPurpose.Others
    }
  ];

  static TITLES = ['Mr', 'Mrs', 'Ms', 'Miss'];
  static loanTenure: number[] = [12, 18, 24, 30, 36];

  static removeSpaceFromString(strValue: string): string {
    return strValue?.replace(/\s/g, '');
  }
}
