import { Directive, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MonoTypeOperatorFunction, Subject, takeUntil } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  private unsubscribeOnComponentDestroy$: Subject<void> = new Subject();
  public unsubscribeOnComponentDestroyTakeUntil$: MonoTypeOperatorFunction<any> = takeUntil(this.unsubscribeOnComponentDestroy$);

  ngOnDestroy() {
    this.unsubscribeOnComponentDestroy$.next();
    this.unsubscribeOnComponentDestroy$.complete();
  }

  validateFormControls(formObj: FormGroup | FormArray): boolean {
    let hasErrors = false;

    Object.entries(formObj.controls).forEach((formCtrlEntry) => {
      const formCtrlName = formCtrlEntry[0];
      const formCtrl = formCtrlEntry[1] as FormGroup | FormArray;

      formCtrl.markAsTouched();

      if (formCtrl.errors) {
        hasErrors = true;

        console.warn('validateFormControls -> ', formCtrlName, formCtrl.errors);
      }

      if (formCtrl.controls) {
        hasErrors = this.validateFormControls(formCtrl) || hasErrors;
      }
    });

    return hasErrors;
  }
}
