import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { CookiesService } from '../cookie/cookies.service';
import { RMSessionDataKeys } from '@app/utils/rm.utilities';
const { RM_COOKIE } = RMSessionDataKeys;

interface ModalSubject {
  data: string,
  state: boolean,
  headerPosition: boolean
}

@Injectable({
  providedIn: 'root'
})
export class PorterService {
  isProd = environment.production;
  private showModalSubject: BehaviorSubject<ModalSubject> = new BehaviorSubject<ModalSubject>({
    data: '',
    state: false,
    headerPosition: this.isProd || !!this.cookieService.getCookie(RM_COOKIE.KEY)
  });
  showModal$ = this.showModalSubject.asObservable();

  constructor(private cookieService: CookiesService) {}

  openModal(data: string) {
    this.showModalSubject.next({
      data: data,
      state: true,
      headerPosition: this.isProd || !!this.cookieService.getCookie(RM_COOKIE.KEY)
    });
  }

  closeModal() {
    this.showModalSubject.next({ data: this.isProd ? '' : 'ForTestBanner', state: !this.isProd, headerPosition: this.isProd || !!this.cookieService.getCookie(RM_COOKIE.KEY) });
  }  
}