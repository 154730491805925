import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { catchError, iif, map, MonoTypeOperatorFunction, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';

import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { ButterCmsService } from '@core/services/butter-cms/butter-cms.service';
import { environment } from '@env/environment';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { DisputeMoreDetailsMetaDataModel, DisputeRequestPayloadResponseModel, DisputeStatus, DisputeTransactionMetaDataModel, DisputeUploadFileTypeResModel, FileUpload, MoreDetailFormControlFields, QuestionnairePayloadModel } from '../models/dispute.models';
import { disputeMaxDateValidator, disputeMinDateValidator } from '../validators/dispute.validator';

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}
@Injectable({
  providedIn: 'root'
})
export class DisputeService {
  private unsubscribeOnComponentDestroy$: Subject<void> = new Subject();
  private unsubscribeOnComponentDestroyTakeUntil$: MonoTypeOperatorFunction<any> = takeUntil(this.unsubscribeOnComponentDestroy$);

  // SF parent window
  windowSource!: MessageEventSource;

  // Meta data
  disputeTransactionMetaData!: DisputeTransactionMetaDataModel[];
  // selected route details
  disputeRouteUrlDetails = new Map<string, DisputeTransactionMetaDataModel>();
  // more details questionary
  questionsProvideMoreDetails!: DisputeMoreDetailsMetaDataModel;
  // Final summary copy
  disputeSummaryPayload!: DisputeRequestPayloadResponseModel;

  observableFrom$!: Observable<any>;

  // Temp to hold selected items - to prepare request payload
  selectedDisputeCategory = new Map<string, QuestionnairePayloadModel>(); // key as CATEGORY & value as selected CHARGE BACK REASON
  disputeDocuments = new Map<string, Array<FileUpload>>();
  // Form Group
  moreDetailsFormGroup!: FormGroup;

  // UI Summary key values
  disputeSummaryUIKeyValue = new Map<string, string>();

  errorMessageData!: string;
  disputeStatus!: DisputeStatus;

  private headerType = { 'Content-Type': 'application/json' };

  constructor(
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private router: Router,
    private butterCmsService: ButterCmsService
  ) {}

  disputeSubmitApi(payload: DisputeRequestPayloadResponseModel, disputeId: string): Observable<DisputeRequestPayloadResponseModel> {
    this.headerType = { 'Content-Type': 'application/json' };

    this.observableFrom$ = this.httpClient.put<DisputeRequestPayloadResponseModel>(environment.apiBaseUrlV2 + environment.disputeCreditCardTransactionAPI + environment.disputeSubmitAPI + disputeId, payload, { headers: this.headerType }).pipe(
      map((res) => res),
      catchError((error) => this.errorHandler(error, environment.disputeSubmitAPI))
    );

    return this.observableFrom$;
  }

  disputeUploadFileApi(disputeId: string, uploadFileType: string, selectedFile: File): Observable<Partial<DisputeUploadFileTypeResModel> | string> {
    this.headerType = {
      'Content-Type': selectedFile.type
    };

    const uploadFileApi = `${environment.apiBaseUrlV2}${environment.disputeCreditCardTransactionAPI}${environment.disputeSubmitAPI}${disputeId}/${environment.disputeS3URLToUploadFile}${uploadFileType}?fileName=${selectedFile.name}`;

    return this.httpClient.get<DisputeUploadFileTypeResModel>(uploadFileApi, { headers: this.headerType }).pipe(
      this.unsubscribeOnComponentDestroyTakeUntil$,
      switchMap((response: DisputeUploadFileTypeResModel) =>
        iif(
          () => !!response.downloadUrl, // prettier-ignore
          this.uploadFileInS3Api(response.downloadUrl, selectedFile).pipe(
            this.unsubscribeOnComponentDestroyTakeUntil$,
            map((res) => res)
          ),
          of({ error: 'No preSignedUrl' })
        )
      ),
      catchError((error) => this.errorHandler(error, uploadFileApi))
    );
  }

  private uploadFileInS3Api(preSignedUrl: string, selectedFile: File): Observable<DisputeUploadFileTypeResModel | string> {
    return this.httpClient.put<DisputeUploadFileTypeResModel>(preSignedUrl, selectedFile, { headers: this.headerType }).pipe(
      map((res) => res),
      catchError((error) => this.errorHandler(error, preSignedUrl))
    );
  }

  getDisputeMetaDataCollection(): Observable<Array<DisputeTransactionMetaDataModel>> {
    return this.butterCmsService.getCollectionContent(ButterCMSConstants.DISPUTE_META_DATA_COLLECTION).pipe(
      map((resp) => {
        const disputeJourneyData = JSON.parse(resp.dispute_collection[0].dispute_meta_data_collection)?.disputeCollection as Array<DisputeTransactionMetaDataModel>;

        this.errorMessageData = resp.dispute_collection[0]?.error_data;

        return disputeJourneyData;
      })
    );
  }

  generateRoutes(disputeColl: DisputeTransactionMetaDataModel[]): string[] {
    return disputeColl?.reduce((paths: string[], current: DisputeTransactionMetaDataModel) => {
      if (current.routeUrl) {
        this.disputeRouteUrlDetails.set(current.routeUrl, current);
        paths.push(current.routeUrl);
      }

      if (current.options) {
        const optionsRouteUrls = this.generateRoutes(current.options);
        paths = paths.concat(optionsRouteUrls);
      }

      return paths;
    }, []);
  }

  buildMoreDetailsFormGroup(moreDetailsOpts: Array<MoreDetailFormControlFields>): FormGroup {
    const formGrpObj: any = {};

    moreDetailsOpts.forEach((formItem: MoreDetailFormControlFields) => {
      // check if there is conditional field to ignore the validators for initial setup
      let validatorsArr: ValidatorFn[] = [];

      if (!formItem?.fieldDependency) {
        formItem.validation.forEach((validatorItem: any) => {
          // Require
          if (validatorItem?.require) {
            validatorsArr.push(Validators.required);
          }

          // minLength
          if (validatorItem?.minLength) {
            validatorsArr.push(Validators.minLength(validatorItem?.minLength));
          }

          // maxLength
          if (validatorItem?.maxLength) {
            validatorsArr.push(Validators.maxLength(validatorItem?.maxLength));
          }

          // Date Validator
          if (validatorItem?.minDaysAllowed) {
            validatorsArr.push(disputeMinDateValidator(validatorItem?.minDaysAllowed));
          }
          if (validatorItem?.maxDaysAllowed) {
            validatorsArr.push(disputeMaxDateValidator(validatorItem?.maxDaysAllowed));
          }
        });
      }

      formGrpObj[formItem.fieldName] = new FormControl(formItem.fieldType === 'number' ? null : '', { validators: validatorsArr });
    });

    // get previous state form values
    const formValues = this.moreDetailsFormGroup?.getRawValue();

    this.moreDetailsFormGroup = this.fb.group(formGrpObj);

    formValues && this.moreDetailsFormGroup.patchValue(formValues);

    return this.moreDetailsFormGroup;
  }

  resetMoreDetailsMetaData(): void {
    this.moreDetailsFormGroup?.reset();

    // Clear the More details & Req Payload if user nav to previous screen
    this.disputeSummaryUIKeyValue.forEach((value, key) => {
      this.selectedDisputeCategory.delete(key);
    });

    this.disputeSummaryUIKeyValue.clear();
  }

  sendDataToReactNativeApp = async (action: string) => {
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(action);
  };

  getFormattedDateAsString(dateVal: string, formatType: string): string {
    return dayjs(dateVal).format(formatType) || '';
  }

  isDateValid(dateVal: string): boolean {
    return dayjs(dateVal).isValid();
  }

  private errorHandler(err: any, apiName: string): Observable<string> {
    console.info(`Error in Dispute service ---> ${err}, API NAME ---> ${apiName}`);

    this.disputeStatus = DisputeStatus.error;

    this.router.navigate([`/${RMRouterLink.DisputeTransaction}/${RMRouterLink.DisputeStatus}`]);

    return of('Error in Dispute service');
  }
}
