import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { ButterCmsService } from '@core/services/butter-cms/butter-cms.service';
import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { ButterCMSModel } from '@core/models/butter-cms.model';
import { isPlatformBrowser, NgClass, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ContainerComponent } from '@shared/components/container/container.component';
import { environment } from '@env/environment';
import { NotificationComponent } from '@shared/components/notification/notification.component';

@Component({
  selector: 'rm-header',
  templateUrl: './header.component.html',
  imports: [NgIf, NgForOf, RouterLink, NgClass, RouterLinkActive, NgOptimizedImage, ContainerComponent, NotificationComponent],
  standalone: true
})
export class HeaderComponent {
  @Input() headerModuleName!: string;
  @Input() isPaymentModule = false;
  @Input() envBannerPositionBottom = environment.production;
  
  @Output() handleHamburgerClick = new EventEmitter<boolean>();
  isBrowserPlatform!: boolean;
  menuItems!: ButterCMSModel.NavigationMenuModel[];
  showHamburger = false;
  signInMenuName = 'My account';

  constructor(private butterCmsService: ButterCmsService, @Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowserPlatform = isPlatformBrowser(platformId);
    this.getMenuItems();
  }

  goToLink(navLink: string, linkUrl: string): void {
    if (this.isBrowserPlatform && this.signInMenuName === navLink) window.location.href = linkUrl;

    return;
  }

  toggleHamburger() {
    this.showHamburger = !this.showHamburger;
    this.handleHamburgerClick.emit(this.showHamburger);
  }

  getMenuItems() {
    this.butterCmsService.getNavigationMenuItem(ButterCMSConstants.NAVIGATION_MENU_ITEMS_API).subscribe((menuItemsData) => {
      this.menuItems = menuItemsData as ButterCMSModel.NavigationMenuModel[];
    });
  }
}
