import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RMUtilities } from '@app/utils/rm.utilities';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const getMinMaxValidatorError = (ctrlVal: string, numberOfDays: number, toValidateBeforeDate = false): ValidationErrors | null => {
  const ctrlDateVal = dayjs(ctrlVal, RMUtilities.DISPUTE_TRANSACTION_MAX_DATE_RANGE);
  const minMaxDateVal = toValidateBeforeDate ? dayjs().subtract(numberOfDays, 'day') : dayjs().add(numberOfDays, 'day');

  const isValidDate = toValidateBeforeDate ? ctrlDateVal?.isSameOrBefore(minMaxDateVal) : ctrlDateVal?.isSameOrAfter(minMaxDateVal);

  if (isValidDate) {
    return toValidateBeforeDate ? { invalidMinDays: true } : { invalidMaxDays: true };
  }

  return null;
};

export const disputeMinDateValidator = (minDaysAllowed: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    return getMinMaxValidatorError(control.value, minDaysAllowed, true);
  };
};

export const disputeMaxDateValidator = (maxDaysAllowed: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    return getMinMaxValidatorError(control.value, maxDaysAllowed);
  };
};

export const disputeTransactionAmountValidator = (transactionAmount: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && !isNaN(transactionAmount)) {
      const partialAmt = Number(control.value);

      if (isNaN(partialAmt) || partialAmt <= 0 || partialAmt >= transactionAmount) {
        return { invalidTransactionAmt: true };
      }
    }

    return null;
  };
};
