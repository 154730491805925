<header class="mt-12 md:mt-[76.8px] lg:mt-0">
  <rm-header [headerModuleName]="moduleName" (handleHamburgerClick)="hamburgerStatus = $event" [envBannerPositionBottom]="envBannerPositionBottom" />
</header>

<div class="relative" [class]="hamburgerStatus ? 'h-screen overflow-hidden' : ''" [ngClass]="envBannerPositionBottom ? 'top-0 lg:top-auto' : 'top-6'">
  <rm-internet-status>
    <main>
      <router-outlet />
    </main>
  </rm-internet-status>

  <footer *ngIf="!isPaymentModule">
    <rm-footer *rmLoadApiOnScroll />
  </footer>
</div>