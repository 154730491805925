import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild, inject } from '@angular/core';

import { toObservable } from '@angular/core/rxjs-interop';
import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { ButterCMSModel } from '@core/models/butter-cms.model';
import { SignalService } from '@shared/service/signal/signal.service';

@Component({
  selector: 'rm-notification',
  standalone: true,
  imports: [NgClass, DatePipe, NgFor, NgIf],
  templateUrl: './notification.component.html'
})
export class NotificationComponent {
  private signalService = inject(SignalService);

  @ViewChild('container') container!: ElementRef;

  notifications: ButterCMSModel.Notification[] = [];

  isOpen = true;

  constructor() {
    toObservable(
      this.signalService.getData(ButterCMSConstants.NOTIFICATION_API, 'getPageContent')
    ).subscribe(
      (notificationsData: any) => {
        if (notificationsData) {
          this.getNotifications(notificationsData.fields.banner);
        }
    });

    this.isOpen && this.addEventListener()
  }

  getNotifications(notifications: ButterCMSModel.Notification[]) {
    this.notifications = notifications.filter(notification =>
      notification.is_enabled &&
      notification.for_marketing && 
      notification.end_date > new Date().toISOString()
    ).sort((n1, n2) => 
      new Date(n2.start_date).getTime() - new Date(n1.start_date).getTime()
    );
  }

  toggleModal() {
    this.isOpen = !this.isOpen;

    this.isOpen && this.addEventListener();
  }

  private addEventListener() {
    const onDocumentClick = (ev: any) => {
      if (!this.container.nativeElement.contains(ev.target) || !this.isOpen) {
        this.isOpen = false;
        document.removeEventListener('click', onDocumentClick);
      }
    }
    this.isOpen && document.addEventListener('click', onDocumentClick);
  }
}
