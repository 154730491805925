import { Injectable } from '@angular/core';
import { CookieCategories, RMSessionDataKeys } from '@app/utils/rm.utilities';
const { RM_COOKIE } = RMSessionDataKeys;
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  cookiePreferences: Partial<{ [key in keyof typeof CookieCategories]: boolean }> = {};

  cookieAccepted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private cookieService: CookieService) {
    let cookie = this.getCookie(RM_COOKIE.KEY);
    if (cookie) {
      cookie === RM_COOKIE.VALUE ? this.removeCookies(RM_COOKIE.KEY) : this.cookiePreferences = JSON.parse(cookie);
    }
  }

  setCookie(key?:string, value?: string): void {
    const expires = new Date();
    expires.setDate(expires.getDate() + RM_COOKIE.EXPIRY_DAYS);
    if (key && value) {
      this.cookieService.set(key, value, expires);
    } else {
      this.cookieService.set(RM_COOKIE.KEY, JSON.stringify(this.cookiePreferences), expires);
      this.cookieAccepted$.next(true);
    }
  }

  getCookie(key:string): string {
    return this.cookieService.get(key);
  }

  removeCookies(key:string): void {
    this.cookieService.delete(key);
  }
}