import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, NgOptimizedImage, isPlatformBrowser } from '@angular/common';

import { ContainerComponent } from "../container/container.component";
import { SafeHtmlPipe } from "../../pipes/safe-html.pipe";
import { NavigationEnd, Router } from '@angular/router';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { filter } from 'rxjs';

@Component({
  selector: 'rm-internet-status',
  standalone: true,
  templateUrl: './internet-status.component.html',
  imports: [CommonModule, ContainerComponent, NgOptimizedImage, SafeHtmlPipe]
})
export class InternetStatusComponent implements OnInit{
  isOnline = true;
  isDisputeFlow = false;
  isDisputeOffline = false;
  imageArr: HTMLImageElement[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router) {
    if (isPlatformBrowser(this.platformId)) {
      this.checkInternetConnection();
      window.addEventListener('online', () => this.checkInternetConnection());
      window.addEventListener('offline', () => this.checkInternetConnection());
      this.isDisputeFlow = this.router.url.includes(RMRouterLink.DisputeTransaction);
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((routeEvent: NavigationEnd) => {
        this.isDisputeFlow = routeEvent.url.includes(RMRouterLink.DisputeTransaction);
    })
  }

  private checkInternetConnection(): void {
    this.isOnline = navigator.onLine;
    !this.isOnline &&  this.scrollToTop();
    this.isDisputeOffline = this.router.url.includes(RMRouterLink.DisputeTransaction) && !this.isOnline;
  }

  private scrollToTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth' // Optional smooth scrolling
    });
  }
}
