import { AggregatorsTypes, LoanProductIDTypes } from '@core/enums/rm.enum';

export namespace RmModel {
  export interface breadCrumbDataModel {
    label: string;
    url: string;
  }

  export interface LoanApplicationResModel {
    applicationID: number;
  }

  export interface LoanApplicationPayloadModel {
    aggregator: string;
    aggr_url: string;
    loanProductID: string;
  }

  export interface LoanQuotePayloadModel {
    user: LoanQuoteUserModel;
    loan: LoanQuoteLoanModel;
    aggregator: AggregatorsTypes;
    applicationId: string;
  }

  export interface LoanQuoteResModel {
    // Approved or Rejected
    loanId: number;
    status: any;
    amount: number;
    currency: string;
    termInMonths: number;

    startedDate?: string;
    expiryDate?: string;
    monthlyRepayment?: number;
    arrangementFee?: number;
    Rate?: number;
    totalCost?: number;
    annualPercentageRate?: string;
    loginUrl?: string;
    autoApproved?: boolean;
    preApproved?: boolean;
    KeyWorker?: string;
    ProductType?: string;
    loanProductID?: string;
    OBdiscountApplicable?: boolean;
    URL?: string;

    // Rejected
    rejectionReasons?: Array<string>;
    errors?: [{ code: number; status: string }];
  }

  export interface AddressUIModel {
    addressType?: string;

    abodeNumber: string;
    buildingNumber?: string;
    buildingName?: string;
    street?: string;
    city?: string;
    county?: string;
    country?: string;
    post_code: string;

    // Only UI prop
    addressFormat: string;
    from?: string;
    outsideUk?: boolean;
  }

  export interface LoanQuoteUserModel {
    title: string;
    first_name: string;
    last_name: string;
    email: string;
    dob: string;
    noofdependents: string;
    addresses: Array<AddressUIModel>;

    middle_name?: string;
    phone?: string;
    association?: string;
    account_number?: string;
    sort_code?: string;
    relationshipstatus?: string;
    employment_position?: string;
    employment_sector?: string;
  }

  export interface LoanQuoteLoanModel {
    amount: string;
    termInMonths: string;
    income: string;

    currency: string;
    rentOrMortgage: string;
    employmentStatus: string;
    employment_position: string;
    employer: string;
    purpose: string;
    purpose_if_other: string;

    employment_sector?: string;
    outgoings?: string;
    queryID?: string;
    association?: string;
    obvid?: string;
    obvdata?: string;
    customerId?: string;
    hasMortgage?: string;
    mortgageRateType?: string;
    fixedRateDuration?: string;
    hasOtherHouseholdIncome?: string;
    additionalHouseholdIncome?: string;
  }

  // ModalDataState model Started
  export interface ModalDataState {
    data: string;
    state: boolean;
  }

  // Dispute Query param
  export interface DisputeQueryParam {
    token: string;
    disputeId: string;
    customerId: string;
    transactionAmount: number;
  }

  export interface PageViewEventModel {
    page_path: string;
    page_title: string;
    page_location: string;
  }
}

export enum ExternalScriptType {
  GoogleTagManagerType = 'GOOGLE_TAG_MANAGER_TYPE',
  GoogleAnalyticsType = 'GOOGLE_ANALYTIC_TYPE'
}

export interface UserFeedbackModel {
  id: string;
  product: string;
  type: string;
  feedback: {
    category: string;
    refName: string;
    refUrl: string;
    wasUseful: boolean;
    comment: string;
  };
}

export interface UserFeedbackResponseModel {
  success: string;
}

export interface ContentBlogList {
  innerHTML: string;
  id: string;
}
