import { Injectable } from '@angular/core';
import Butter from 'buttercms';
import { catchError, from, map, Observable } from 'rxjs';

import { ButterCMSModel } from '@core/models/butter-cms.model';
import { environment } from '@env/environment';
import { ErrorService } from '../error/error.service';
import { PORTER_STATES } from '@app/utils/rm.utilities';
import { ButterCMSConstants } from '@core/constants/butter-cms.constant';

export const butterService = Butter(environment.butterCmsAPIKey);

@Injectable({
  providedIn: 'root'
})
export class ButterCmsService extends ErrorService {
  observableFrom$!: Observable<any>;

  RM_LEGAL_NO_TITLE = 'rm-legal-no-title';

  /**
   * Retrieve Collection contents (Nav menu, Cookies)
   * @returns Collection content
   */
  getCollectionContent(collectionName: string, payload?: Butter.ContentParams<object>): Observable<any> {
    payload = { levels: 3, ...payload };

    this.observableFrom$ = from(butterService.content.retrieve([collectionName] as any, payload)).pipe(
      map((resp: any) => resp.data.data),
      catchError((error) => this.errorHandler(error, 'Error in Butter CMS Collection'))
    );

    return this.observableFrom$;
  }

  /**
   * Retrieve Page content (Home, Loans, About us, & Help)
   * @returns Page content
   */
  getPageContent(pageName: string): Observable<any> {
    if( pageName !== ButterCMSConstants.SEO_METADATA ) this.showLoader(PORTER_STATES.LOADER);
    this.observableFrom$ = from(butterService.page.retrieve('*' as any, pageName)).pipe(
      map((resp: any) => {
        if( pageName !== ButterCMSConstants.SEO_METADATA ) this.closeLoader();
        return resp.data.data;
      }),
      catchError((error) => this.errorHandler(error, `Error with Butter CMS ${pageName.split('-').map(e => e.charAt(0).toUpperCase() + e.slice(1)).join(' ').replace('Rm', 'Reevo Money')}`))
    );

    return this.observableFrom$;
  }

  /**
   * Retrieve Blog Post list content (Legals, Loans blogs, other blog post)
   * @returns Blog post list content
   */
  getBlogPostContent(blogPostReqPayload: ButterCMSModel.BlogPostPayload): Observable<any> {
    this.showLoader(PORTER_STATES.LOADER);
    this.observableFrom$ = from(butterService.post.list(blogPostReqPayload)).pipe(
      map((resp: any) => {
        this.closeLoader();
        return resp.data.data;
      }),
      catchError((error) => this.errorHandler(error, `Error in Blog Post ${blogPostReqPayload.category_slug}`))
    );

    return this.observableFrom$;
  }

  /**
   * Retrieve selected Legals Blog Post page content
   * @returns Legals Blog post page content
   */
  getLegalPageData(legal_page_api: string): Observable<ButterCMSModel.LegalPageModel> {
    this.showLoader(PORTER_STATES.LOADER);
    this.observableFrom$ = from(butterService.post.retrieve(legal_page_api)).pipe(
      map((resp: any) => {
        const legalData = resp.data.data;
        const shouldHideTitle = legalData.tags.some((item: ButterCMSModel.PageBaseModel) => item.slug === this.RM_LEGAL_NO_TITLE);
        this.closeLoader();
        return {
          title: legalData.title,
          body: legalData.body,
          shouldHideTitle: shouldHideTitle
        } as ButterCMSModel.LegalPageModel;
      }),
      catchError((error) => this.errorHandler(error, `Error in Butter CMS legal page ${legal_page_api}`))
    );
    return this.observableFrom$;
  }

  /**
   * Get Menus from Butter CMS
   * @returns Menu Items
   */
  getNavigationMenuItem(menuItem: string): Observable<ButterCMSModel.NavigationMenuModel[]> {
    this.observableFrom$ = from(
      butterService.content.retrieve([menuItem], {
        levels: 3
      })
    ).pipe(
      map((resp: any) => resp.data.data.navigation_menu[0].menu_items as ButterCMSModel.NavigationMenuModel[]),
      catchError((error) => this.errorHandler(error, 'Error in Butter CMS menu items'))
    );

    return this.observableFrom$;
  }

  /**
   * Retrieve Blog post page content
   * @returns Blog post page content
   */
  getBlogPage(slug: string): Observable<ButterCMSModel.LegalPageModel> {
    this.showLoader(PORTER_STATES.LOADER);
    this.observableFrom$ = from(butterService.post.retrieve(slug)).pipe(
      map((resp: any) => {
        this.closeLoader();
        return resp.data.data;
      }),
      catchError((error) => this.errorHandler(error, `Error in Butter CMS blog posts${slug}`))
    );

    return this.observableFrom$;
  }
}
