import { Injectable } from '@angular/core';
import { CookiesService } from '../cookie/cookies.service';
import { RmModel } from '@core/models/rm-model';

function gtag(event_type: string, args: any) {
  window.dataLayer.push('event', event_type, args);
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private cookiesService: CookiesService) { }

  triggerPageViewEvent(args: RmModel.PageViewEventModel) {
    this.cookiesService.cookiePreferences.Analytics && gtag('page_view', args);
  }
}
