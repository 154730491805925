import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButterCMSModel } from '@core/models/butter-cms.model';

@Component({
  selector: 'rm-cookie-accordion',
  standalone: true,
  imports: [NgClass, NgIf],
  templateUrl: './cookie-accordion.component.html'
})
export class CookieAccordionComponent {
  @Input() cookieType!: ButterCMSModel.CookieCategory
  @Output() enableCookie = new EventEmitter<boolean>();

  isOpen = false;
  isEnabled = false;

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  toggleCookie() {
    this.isEnabled = !this.isEnabled;
    this.enableCookie.emit(this.isEnabled);
  }
}