import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CustomHttpInterceptor } from '@core/services/interceptor/custom.interceptor';
import { AppRoutingModule } from '@routing/app-routing.module';
import { AppComponent } from './app.component';
import { PorterComponent } from '@shared/components/porter/porter.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InternetStatusComponent } from '@shared/components/internet-status/internet-status.component';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, PorterComponent, BrowserAnimationsModule, InternetStatusComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
