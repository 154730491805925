<ng-container *ngIf="cookiesItems">
  <div class="fixed bottom-0 z-10 py-6 px-4 sm:px-10 flex flex-col gap-4 sm:flex-row sm:gap-10 w-full justify-between bg-neutral-50 border-t border-t-neutral-200 shadow-cookie">
    <div>
      <p [innerHtml]="cookiesItems.cookie[0].paragraph_content | safeHtml"></p>
    </div>
    <div class="grid grid-cols-2 sm:flex sm:justify-center gap-3">
      <button class="btn secondary sm:my-auto text-sm col-span-1 sm:mt-auto sm:whitespace-nowrap w-full sm:w-fit" (click)="openCookieSettings()">Cookie settings</button>
      <button class="btn primary sm:my-auto text-sm col-span-1 sm:mt-auto sm:whitespace-nowrap w-full sm:w-fit" (click)="acceptAllCookies()">Accept all cookies</button>
    </div>
  </div>

  <ng-container *ngIf="isSettingsOpen">
    <div class="fixed top-0 left-0 z-[51] w-full h-full bg-black bg-opacity-60 flex justify-center items-center">
      <div class="relative mx-4 rounded p-7 bg-white max-w-[600px]">
        <div class="flex flex-col gap-6 bg-white pb-16 rounded overflow-y-auto max-h-[70vh] sm:max-h-[500px] scrollbar">
          <div class="flex flex-col gap-3">
            <div class="flex justify-between items-center">
              <h6 class="text-lg/6">{{ cookiesItems.cookie[0].policy_heading }}</h6>
              <img class="cursor-pointer" src="../../../../assets/logos/close-x-icon.svg" width="24" height="24" alt="close" (click)="closeCookieSettings()" />
            </div>
            <div>
              <div [ngClass]="showPolicyText ? '' : 'line-clamp-6 sm:line-clamp-4'" [innerHTML]="cookiesItems.cookie[0].policy_description"></div>
              <p class="underline cursor-pointer md-link" (click)="showPolicyText = !showPolicyText">{{ showPolicyText ? 'Show less' : 'Show more'}}</p>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <ng-container *ngFor="let cookieType of cookiesItems.cookie[0].cookie_categories; let i = index">
              <rm-cookie-accordion [cookieType]="cookieType" (enableCookie)="toggleCookie(i, $event)" />
            </ng-container>
          </div>
        </div>
        <div class="absolute left-0 bottom-0 flex justify-center sm:justify-end py-3 px-7 w-full bg-white shadow-cookie rounded-b">
          <button class="btn primary text-sm w-full sm:w-fit" (click)="save()">Save cookie settings</button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>