import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { LoanUtilities } from '@features/loans/utils/loans.utilities';
import { dateOfBirthSessionModel } from '@features/loans/components/find-my-address/dob/models/dob.model';

@Injectable({ providedIn: 'root' })
export class DobValidator {
  customDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const day = control.get('day')?.value;
    const month = control.get('month')?.value;
    const year = control.get('year')?.value;

    // Add your custom validation logic here
    if (day && month && year) {
      // Example validation: The day, month, and year must form a valid date
      if (year > new Date().getFullYear() - 1) {
        return { invalidDOB: true };
      }
    }

    return null;
  };

  ageRequiredValidatorLoans: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if ((control.value && this.calculateDOBAge(control.value) < LoanUtilities.RM_DOB_MIN_AGE_LOANS) || this.calculateDOBAge(control.value) > LoanUtilities.RM_DOB_MAX_AGE_LOANS) {
      return { requiredAge: true };
    }
    return null;
  };

  calculateDOBAge(dob: dateOfBirthSessionModel): number {
    let age = 0;
    const { day, month, year } = dob;
    if (dob) {
      const ageDiffMilliseconds = Date.now() - new Date(`${month}/${day}/${year}`).getTime();
      const ageDate = new Date(ageDiffMilliseconds);
      age = Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    return age;
  }
}
