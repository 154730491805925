import { ButterCMSModel } from "@core/models/butter-cms.model";

export enum FileUploadType {
  CorrectAmount = 'CORRECT_AMOUNT',
  PaidByOtherMeans = 'PAID_BY_OTHER_MEANS'
}

export type ToastVariant = 'success' | 'warning' | 'critical' | 'info' | '3DS';

export type Toast = {
  id?: string;
  variant: ToastVariant;
  title?: string;
  description: string;
};

export enum DisputeStatus {
  submit = 'SUBMIT',
  error = 'error'
}

// CMS Meta data model
export interface DisputeHeadingAPIKeyMetaDataModel {
  heading: string;
  apiKey: string;

  type?: unknown;
}

export interface FieldValidationMetaDataModel {
  errorMessage: string;
  [key: string]: string | number | boolean;
}
export interface MoreDetailsMetaDataOptionsModel extends DisputeHeadingAPIKeyMetaDataModel {
  fieldName: string;
  fieldType: string;

  pattern?: string;
  maxDate?: string;
  validation?: Array<FieldValidationMetaDataModel>;
  fieldStyleType?: string;
  radioBtnOptions?: Array<{ value: string; label: string }>;
  fieldDependency?: string | undefined;
  fieldDependencyValue?: string | undefined;
  fieldPlaceHolder?: string | undefined;
  hintMessage?: string;
}

export interface DocRequireMetaModel extends DisputeHeadingAPIKeyMetaDataModel {
  paragraph: string;
  
  retailerCeasedOperation?: boolean;
  anyAdditionalTransactionAssociateToClaim?: boolean;
  documentCategories: Array<documentUploadType>
}

export interface documentUploadType {
  fileUploadHeading: string;
  fileUploadParagraph: string;
  fileUploadType: string;
}

export interface DisputeMoreDetailsMetaDataModel extends DisputeHeadingAPIKeyMetaDataModel {
  paragraph: string;

  options?: Array<MoreDetailsMetaDataOptionsModel>;
  docsRequired?: DocRequireMetaModel;
}

export interface DisputeTransactionMetaDataModel extends DisputeHeadingAPIKeyMetaDataModel {
  options: Array<DisputeTransactionMetaDataModel>;

  moreDetails?: DisputeMoreDetailsMetaDataModel;
  multiSelectOptions?: Array<DisputeHeadingAPIKeyMetaDataModel>;
  noneOfAbove?: boolean | string;
  nextBtn?: boolean;
  routeUrl?: string;
}

// END CMS Meta data model

// Dispute submit req payload
export interface QuestionnairePayloadModel {
  question: { key: string; title: string };
  answers: Array<{ key: string; value: string; dataType: string }>;
}

export interface FileUpload {
  file: File;
  fileName: string;
  fileContentType: string;
  fileId: string;

  fileUrl?: string;
  uploadFileType?: FileUploadType;
  downloadUrl?: string;
}

export interface DocumentsTypeModel {
  documentType: string;
  downloadUrls: Array<string>;
}

export interface DisputeRequestPayloadResponseModel {
  questionnaire: Array<QuestionnairePayloadModel>;
  documentProof: Array<DocumentsTypeModel>;

  transactions?: Array<{ transactionId: string }>;
  status?: string;
}
// END of Submit api

// Form control models
export enum DisputeMetaDataFormControlType {
  Number = 'number',
  Text = 'text',
  Date = 'date',
  TextArea = 'textarea',
  RadioBtn = 'radio',
  Boolean = 'boolean'
}

//  Reactive Form group controls model
export interface MoreDetailFormControlFields {
  fieldName: string;
  fieldType: string;
  validation: Array<FieldValidationMetaDataModel>;

  fieldDependency?: string;
}

export interface DisputeUploadFileTypeResModel {
  disputeId: string;
  uploadFileType: FileUploadType;
  downloadUrl: string;

  error?: string;
}

// SF integration model
export enum DisputeSFIntegrationEventType {
  LaunchDisputeJourneyEvent = 'LAUNCH_DISPUTE_JOURNEY_EVENT',
  SubmitDisputeEvent = 'SUBMIT_DISPUTE_EVENT'
}
export interface DisputeSFIntegrationMessageEventModel {
  messageType: DisputeSFIntegrationEventType;
  message: string;
}

// Mobile app
export class MobileAppEvents {
  static BackToTransaction = JSON.stringify({
    type: 'BACK_TO_TRANSACTION'
  });
  static createToastEvent(variant: Toast['variant'], description: string) {
    return JSON.stringify({
      type: 'TOAST',
      variant: variant,
      description: description
    });
  }
}


// Status
export class DisputeStatusContent {
  static readonly noSupport: ButterCMSModel.ComponentBaseModel = {
    heading: "We won't be able to support your case",
    image: "https://cdn.buttercms.com/rounded_corners=radius:max/cFkk1TTOTEWGxz4V6jCI",
    paragraph_content: "Your issue does not match any of the required criteria.<br><br>If you made a mistake, feel free to go back and choose the option that best describes your situation.",
    sub_heading: "Return to transaction"
  }

  static readonly submitted: ButterCMSModel.ComponentBaseModel = {
    heading: "Claim submitted",
    image: "https://cdn.buttercms.com/n9zmJ1GqQSO2ZBOgRJYk",
    paragraph_content: "Thank you for submitting your claim. We aim to process your claim within 10 business days (Mon-Fri).<br><br>We'll contact you via email to keep you updated with the progress of your claim.",
    sub_heading: "OK"
  }

  static readonly contactUs: ButterCMSModel.ComponentBaseModel = {
    heading: "Contact us",
    image: "https://cdn.buttercms.com/ubVHh3GXQiWyEXG0rsnR",
    paragraph_content: "If you suspect fraud or have other issues with a transaction, please call us. We are here to help you out. ",
    sub_heading: "Return"
  }
}