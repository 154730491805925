import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from '@app/app.service';
import { AggregatorsTypes } from '@core/enums/rm.enum';
import { environment } from '@env/environment';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private router = inject(Router);
  private appService = inject(AppService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let API_KEY = environment.rvoDirectProductAPIKey;
    let authReq: HttpRequest<any>;

    if (req.body?.aggregator && [environment.createLoanApplicationAPI, environment.createQuoteAPI].some((path: string) => req.url.includes(path))) API_KEY = req.body.aggregator === AggregatorsTypes.SearchEngine ? environment.DIRECT_SEARCH_AGGREGATOR_API_KEY : environment.rvoDirectProductAPIKey;

    if (req.url.includes('feedback')) {
      return next.handle(req)
    }

    let headerType: any = {
      'Content-Type': 'application/json',
      'X-API-KEY': API_KEY
    };

    // Dispute journey
    const isDisputeJourney = this.appService.disputeQueryParam?.disputeId;

    if (req.url.includes(environment.disputeSubmitAPI)) {
      headerType = {
        'Content-Type': req.headers.get('Content-Type'), // get value from dispute service,
        Authorization: `Bearer ${this.appService.disputeQueryParam?.token}`,
        'Mosaic-Customer-Id': this.appService.disputeQueryParam?.customerId
      };
    }

    authReq = req.clone({ headers: new HttpHeaders(headerType) });

    return isDisputeJourney
      ? next.handle(authReq)
      : next.handle(authReq).pipe(
          catchError((err) => {
            this.router.navigate([`/${RMRouterLink.Error}/${RMRouterLink.SomethingWentWrong}`]);

            return throwError(() => new Error(`${err} - Error in service`));
          })
        );
  }
}
