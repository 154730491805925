import { Injectable, Signal, WritableSignal, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ButterCmsService } from '@core/services/butter-cms/butter-cms.service';

@Injectable({
  providedIn: 'root'
})
export class SignalService {

  apiResponses = signal<any>({});

  private butterCmsService = inject(ButterCmsService);

  private updateStore(key: string, val: Signal<any>) {
    this.apiResponses.update(oldResponses => ({
      ...oldResponses, [key] : val
    }));
  }

  getData(apiKey: string, butterCmsAction: keyof ButterCmsService, payload?: any){
    if(!this.apiResponses()[apiKey]) {
      let action = payload ? (this.butterCmsService as any)[butterCmsAction](payload) : (this.butterCmsService as any)[butterCmsAction](apiKey);
      this.updateStore(apiKey, toSignal(action));
    }
    return this.apiResponses()[apiKey];
  }
}
