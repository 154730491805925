export enum QuoteStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export enum LoanPurpose {
  CarPurchase = 'CARP',
  HomeImprovement = 'HOME',
  DebtConsolidation = 'DC',
  LivingExpenses = 'PERSONALEXP',
  Holiday = 'HOL',
  Others = 'Other'
}
