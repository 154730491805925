import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

import { AppService } from '@app/app.service';
import { PORTER_STATES, RMSessionDataKeys } from '@app/utils/rm.utilities';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { CookiesService } from '@shared/service/cookie/cookies.service';
import { PorterService } from '@shared/service/porter/porter.service';

const { RM_COOKIE } = RMSessionDataKeys;

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  protected router!: Router;
  protected porterService!: PorterService;
  protected cookieService!: CookiesService;
  protected appService!: AppService;

  constructor() {
    this.injectServices();

    const isAvailable = Object.keys(this.appService.getRmSessionData(RMSessionDataKeys.RM_LAST_TRIED_ROUTE)).length !== 0;

    if (isAvailable) {
      this.router.navigate([this.appService.getRmSessionData(RMSessionDataKeys.RM_LAST_TRIED_ROUTE).toString()]);
      this.appService.removeRmSessionData(RMSessionDataKeys.RM_LAST_TRIED_ROUTE);
    }
  }

  errorHandler(error: HttpErrorResponse, message: string) {
    this.appService.saveRMSessionData(RMSessionDataKeys.RM_ERROR_DETAILS, this.getErrorMsg(error, message));
    this.appService.saveRMSessionData(RMSessionDataKeys.RM_LAST_TRIED_ROUTE, this.router.url, `/${RMRouterLink.Error}/${RMRouterLink.SomethingWentWrong}`);
    this.porterService.closeModal();
    this.router.navigate([`/${RMRouterLink.Error}/${RMRouterLink.SomethingWentWrong}`]);

    return throwError(() => new Error(`${error} - Error in ${message}`));
  }

  closeLoader() {
    this.cookieService.getCookie(RM_COOKIE.KEY) ? this.porterService.closeModal() : this.showLoader(PORTER_STATES.COOKIE_BANNER);
  }

  showLoader(data: string) {
    this.porterService.openModal(data);
  }

  private injectServices(): void {
    this.router = inject(Router);
    this.porterService = inject(PorterService);
    this.cookieService = inject(CookiesService);
    this.appService = inject(AppService);
  }
  private getErrorMsg(err: HttpErrorResponse, msg: string): string {
    if(err.error?.traceId) return `Trace Id : ${err.error.traceId}`;
    else if(msg.includes('Butter')) return msg;
    return 'Error in service.';
  }
}
