import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DisputeComponent } from '@app/dispute/dispute.component';
import { PaymentContentContainerComponent } from '@app/payments/components/payment-content-container/payment-content-container.component';
import { ContentContainerComponent } from '@core/components/content-container/content-container.component';
import { RMRouterLink } from './rm-routing.enum';

export const routes: Routes = [
  {
    path: RMRouterLink.DisputeTransaction,
    component: DisputeComponent,
    loadChildren: () => import('@app/dispute/routing/dispute-transaction-routing').then((disputeTransaction) => disputeTransaction.DisputeTransactionRoutes),
    data: {
      module: RMRouterLink.DisputeTransaction
    }
  },
  {
    path: RMRouterLink.ActivateCard,
    loadComponent: () => import('@app/universal-redirect/universal-redirect.component').then((activateCard) => activateCard.UniversalRedirectComponent),
    data: {
      module: RMRouterLink.ActivateCard
    }
  },
  {
    path: RMRouterLink.CreditCardUploadDoc,
    loadComponent: () => import('@app/universal-redirect/universal-redirect.component').then((activateCard) => activateCard.UniversalRedirectComponent),
    data: {
      module: RMRouterLink.ActivateCard
    }
  },
  {
    path: RMRouterLink.CreditCardLogin,
    loadComponent: () => import('@app/universal-redirect/universal-redirect.component').then((activateCard) => activateCard.UniversalRedirectComponent),
    data: {
      module: RMRouterLink.ActivateCard
    }
  },
  {
    path: RMRouterLink.PaymentOpenBanking,
    component: PaymentContentContainerComponent,
    loadChildren: () => import('@app/payments/routing/payment-routing').then((routing) => routing.PaymentRouts),
    data: {
      module: RMRouterLink.Payment
    }
  },
  {
    path: RMRouterLink.PaymentRedirect,
    loadComponent: () => import('@app/payments/components/payment-redirect/payment-redirect.component').then((paymentRedirect) => paymentRedirect.PaymentRedirectComponent),
    data: {
      module: RMRouterLink.Payment
    }
  },
  {
    path: '',
    component: ContentContainerComponent,
    loadChildren: () => import('@features/routing/features.routes').then((Features) => Features.FeatureRoutes)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
