<ng-container *ngIf="collectionData() as footerDataModel">
  <rm-container themeClasses="bg-primary-600 bottom-0">
    <div class="grid grid-cols-12 gap-4 lg:gap-8">
      <div class="col-span-12 sm:col-span-9">
        <div class="grid grid-cols-9">
          <div class="col-span-12 sm:col-span-8 lg:col-span-5">
            <div class="grid grid-cols-5">
              <div class="col-span-5 mr-4 sm:col-span-1">
                <img routerLink="/" class="cursor-pointer" width="96" height="76" [ngSrc]="footerDataModel.rm_footer[0].icon" alt="Reevo Money" />
              </div>
              <div class="col-span-5 py-4 text-base-white sm:col-span-4 sm:px-4 sm:py-0"
                [innerHTML]="footerDataModel.rm_footer[0].paragraph_content | safeHtml"></div>
            </div>
          </div>

          <div class="col-span-12 pt-8 sm:col-span-8 lg:col-span-4 lg:pt-0 ms-0 sm:ms-4">
            <div class="grid grid-cols-4">
              <div class="col-span-2">
                <p class="lg-bold pb-1 text-base-white">Our offers</p>
                <ul class="md-regular list-none">
                  <li *ngFor="let link of footerDataModel.rm_footer[0].rm_links" class="py-1">
                    <a [routerLink]="link.url" class="text-base-white hover:underline">{{ link.label }}</a>
                  </li>
                </ul>
              </div>

              <div class="col-span-2">
                <p class="lg-bold pb-1 text-base-white"><a [routerLink]="legal_url" class="cursor-pointer">Legal</a></p>
                <ul class="md-regular list-none">
                  <li *ngFor="let legalPage of footerDataModel.rm_footer[0].rm_legal_links" class="py-1">
                    <a [routerLink]="legalPage.url" class="text-base-white hover:underline">{{ legalPage.label }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-span-12 my-4 border-l-0 pl-0 text-base-white sm:col-span-3 sm:my-0 sm:border-l-2 sm:pl-4 sm:ms-auto">
        <p class="lg-bold pb-1 text-base-white"><a [routerLink]="help_url" class="cursor-pointer">Help</a></p>
        <ul class="md-regular list-none">
          <li *ngFor="let legalPage of footerDataModel.rm_footer[0].rm_help_links" class="py-1">
            <a [routerLink]="legalPage.url" class="text-base-white hover:underline">{{ legalPage.label }}</a>
          </li>
        </ul>
      </div>
      <div class="sm-regular col-span-12 border-t-2 py-4 sm:border-t-0 sm:py-8 text-base-white"
        [innerHtml]="footerDataModel.rm_footer[0].copy_right_content | safeHtml"></div>
    </div>
  </rm-container>
</ng-container>