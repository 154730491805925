import { ButterCMSModel } from '@core/models/butter-cms.model';

export class ButterCMSConstants {
  // Home page
  static readonly HOME_PAGE_API = 'rm-home-page';
  static readonly RM_LOAN_CALCULATOR_FORMULA_DATA = 'rm_loan_calculator_formula';

  // About Us page
  static readonly CREDIT_CARD_PAGE_API = 'rm-credit-card-page';

  //Legal
  static readonly LEGAL_PAGE_BLOG_CATEGORY_API = 'legal';

  // Loans page
  static readonly LOANS_PAGE_API = 'rm-loans-page';
  static readonly LOAN_CALCULATOR_FORMULA_API = 'rm_loan_calculator_formula';
  static readonly LOAN_QUOTE_REJECT = 'rm_quote_reject_page';

  // About Us page
  static readonly ABOUT_US_API = 'rm-about-us';

  // Help page
  static readonly HELP_PAGE_API = 'rm-help-page'

  //collections
  static readonly NAVIGATION_MENU_ITEMS_API = 'navigation_menu';
  static readonly FOOTER_PAGE_API = 'rm_footer';

  static readonly ID_VERIFICATION_API = 'reevo-id-verification';
  static readonly SOMETHING_WENT_WRONG = 'rm_something_went_wrong';
  static readonly PAGE_NOT_FOUND = 'rm_page_not_found';
  static readonly GUIDE_FOR_BANK_STATEMENT = 'how-to-download-bank-statements';

  // Tags
  static readonly RM_LEGAL_NO_TITLE = 'rm-legal-no-title';

  // Reducer api enums

  // ButterCMS Blogs post payload object
  static blogPostPayload: ButterCMSModel.BlogPostPayload = {
    page: 1,
    page_size: 10,
    category_slug: ''
  };

  //Cookies
  static readonly COOKIE = 'cookie';

  // SEO <Metadata>
  static readonly SEO_METADATA = 'seo-metadata';

  // Dispute Collection
  static readonly DISPUTE_META_DATA_COLLECTION = 'dispute_collection';

  // Notifications
  static readonly NOTIFICATION_API = 'reevo-portal-customer-banner';
}
