export enum AddressHistoryType {
  Old = 'OLD',
  Current = 'CURRENT'
}

export enum Currency {
  Gbp = 'GBP'
}

export enum LoanProductIDTypes {
  ProductId_LA_0034 = 'L-0034'
}

export enum AggregatorsTypes {
  Direct = 'RVO_DIRECT',
  SearchEngine = 'RVO_DIRECT_SEARCH',
}

export enum EmploymentStatus {
  FullTimeEmployed = 'em',
  Homemaker = 'hm',
  PartTimeEmployeed = 'pem',
  Retired = 're',
  SelfEmployed = 'se',
  Student = 'st',
  Unemployed = 'un',
  BusinessOwner = 'bo',
  Contractor = 'ce'
}


export enum ResidentialStatus {
  Owner = 'OWNER',
  NotOwner = 'NOTOWNER',
  OwnerWithMortgage = 'OWNER_WITH_MORTGAGE',
  SocialHousingIndicator = 'SOCIAL_HOUSING_INDICATOR',
  CouncilTenant = 'COUNCIL_TENANT'
}
