import { AfterViewInit, Directive, HostListener, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[rmLoadApiOnScroll]',
  standalone: true
})
export class LoadApiOnScrollDirective implements AfterViewInit, OnDestroy{
  private isScrolling = false;
  private isScrollPossible!: boolean;
  private routerSubscription!: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private router: Router
  ) {
    this.routerSubscription = this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        setTimeout(() => this.checkScrollingPossibility(), 100);
      }
    });
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (!this.isScrolling && this.isScrollPossible) {
      this.isScrolling = true;
      this.vcr.createEmbeddedView(this.templateRef);
      this.routerSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.checkScrollingPossibility(), 100);
  }

  private checkScrollingPossibility(): void {
    this.isScrollPossible = document.body.scrollHeight > window.innerHeight;

    if (!this.isScrollPossible) {
      this.vcr.createEmbeddedView(this.templateRef);
      this.routerSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
