<rm-internet-status> 
  <rm-container class="rm-dispute">
    <!-- Loading screen -->
    <ng-container *ngIf="!isDisputeMetaDataLoaded">
      <rm-loader />
    </ng-container>

    <!-- Main Routing screen -->
    <ng-container *ngIf="isDisputeMetaDataLoaded">
      <!-- Back Nav -->
      <div class="w-full h-8">
        <img width="24" height="24" (click)="onBackNav()" [ngSrc]="'./assets/logos/dispute-transaction-back-nav-left.png'"  [ngClass]="isSubmitPage() ? 'invisible' : 'visible'" alt="Back Nav" />
      </div>

      <router-outlet></router-outlet>
    </ng-container>
  </rm-container>
<rm-internet-status /> 