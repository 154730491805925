<ng-container *ngIf="disputeMetaData$ | async as disputeMetaDataModel">
  <div class="mb-6 p-4 xs:p-2">
    <h1 class="text-accent-ink">{{ disputeMetaDataModel.heading }}</h1>
  </div>

  <ul class="flex flex-col space-y-2">
    <ng-container *ngIf="!disputeMetaDataModel.multiSelectOptions">
      <!-- Single selections -->
      <li class="flex w-full justify-between items-center p-4 bg-gray-50 rounded-xl" *ngFor="let disputeItem of disputeMetaDataModel.options" (click)="onNavigateToSubPage(disputeItem)">
        <p class="text-gray-900 lg-regular">{{ disputeItem.heading }}</p>

        <img width="16" height="16" class="w-4 h-4" [ngSrc]="'./assets/logos/chevron-right.svg'" alt="Next" />
      </li>
    </ng-container>

    <!-- Multi select options -->
    <ng-container *ngIf="disputeMetaDataModel.multiSelectOptions">
      <li class="flex w-full justify-between items-center p-4 bg-gray-50 rounded-xl" *ngFor="let disputeItem of disputeMetaDataModel.multiSelectOptions; let idx = index">
        <div class="flex items-center">
          <input type="checkbox" [id]="'multiOpt' + idx" [name]="'multiOpt' + idx" class="checkbox-input me-2 pe-4 focus:shadow-none" [checked]="isChecked(disputeItem.apiKey)" (change)="onMultiOptionHandler($event, disputeItem)" />
          <label [for]="'multiOpt' + idx" class="lg-regular text-gray-900">{{ disputeItem.heading }}</label>
        </div>
      </li>
    </ng-container>

    <!-- None of the above option  -->
    <li *ngIf="disputeMetaDataModel.noneOfAbove" class="flex w-full justify-between items-center p-4 bg-gray-50 rounded-xl" (click)="onNavigateToNoSupport()">
      <p class="text-gray-900 lg-regular">None of the above</p>
      <img width="16" height="16" class="w-4 h-4" [ngSrc]="'./assets/logos/chevron-right.svg'" alt="Next" />
    </li>
  </ul>

  <rm-dispute-next-btn *ngIf="disputeMetaDataModel.multiSelectOptions && disputeMetaDataModel.nextBtn" [isDisabled]="!(this.selectedMultiOptions.length > 0)" (next)="onNextScreen(disputeMetaDataModel)">Next</rm-dispute-next-btn>
</ng-container>
