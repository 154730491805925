import { AsyncPipe, DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';

import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { ButterCMSModel } from '@core/models/butter-cms.model';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { CookiesService } from '@shared/service/cookie/cookies.service';
import { CookieCategories } from '@app/utils/rm.utilities';
import { PorterService } from '@shared/service/porter/porter.service';
import { CookieAccordionComponent } from '../cookie-accordion/cookie-accordion/cookie-accordion.component';
import { ButterCmsService } from '@core/services/butter-cms/butter-cms.service';

@Component({
  selector: 'rm-cookie',
  standalone: true,
  templateUrl: './cookie.component.html',
  imports: [SafeHtmlPipe, NgIf, AsyncPipe, NgClass, NgFor, CookieAccordionComponent]
})
export class CookieComponent {
  cookiesItems!: ButterCMSModel.CookieComponentModel;
  
  showPolicyText = false;
  isSettingsOpen = false;

  constructor(private cookieService: CookiesService, private porterService: PorterService, @Inject(DOCUMENT) private document: Document, private butterCmsService: ButterCmsService) {
    this.butterCmsService.getCollectionContent(ButterCMSConstants.COOKIE).subscribe(items => {
      this.cookiesItems = items;
      this.setAllCookies(false);
    })
  }

  acceptAllCookies(): void {
    this.setAllCookies(true);
    this.save();
  }

  openCookieSettings(): void {
    this.document.body.style.overflowY = 'hidden';
    this.isSettingsOpen = true;
  }

  closeCookieSettings(): void {
    this.document.body.style.overflowY = '';
    this.isSettingsOpen = false;
  }

  save(): void {
    this.cookieService.setCookie();
    this.closeCookieSettings();
    this.porterService.closeModal();
  }

  toggleCookie(index: number, isEnabled: boolean): void {
    this.cookieService.cookiePreferences = {
      ...this.cookieService.cookiePreferences,
      [this.cookiesItems.cookie[0].cookie_categories[index].category as keyof typeof CookieCategories]: isEnabled
    }
  }

  setAllCookies(enableAll: boolean): void {
    this.cookiesItems.cookie[0].cookie_categories.forEach((cookieType, i) => {
      !cookieType.always_enabled && this.toggleCookie(i, enableAll);
    });
  }
}