<div class="w-full" [ngClass]="showCallSupport ? 'h-28 mt-8' : 'h-12 mt-4'"></div>
<div class="border-t border-gray-100 p-4 pb-7 fixed bottom-0 left-0 w-full bg-white z-10 flex flex-col gap-4">
  <a *ngIf="showCallSupport" href="tel:+442045875000" class="btn py-3.5 leading-6 lg-bold rounded-xl normal-case w-full truncate bg-primary-50 text-primary-800 flex gap-2 justify-center items-center">
    <img ngSrc="./assets/logos/phone-call.svg" [width]="24" [height]="24" alt="contact us" />
    Call support
  </a>

  <button [type]="buttonType" class="btn primary py-3.5 text-white leading-6 lg-bold rounded-xl w-full truncate normal-case" [ngClass]="isDisabled ? 'disabled' : ''" [disabled]="isDisabled" (click)="onClick($event)">
    <ng-content />
  </button>
</div>
