import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rm-dispute-next-btn',
  standalone: true,
  imports: [NgClass, NgIf, NgOptimizedImage],
  templateUrl: './dispute-next-btn.component.html'
})
export class DisputeNextBtnComponent {
  @Input() buttonType: string = 'button';
  @Input() isDisabled: boolean = false;
  @Input() showCallSupport: boolean = false;

  @Output() next: EventEmitter<Event> = new EventEmitter<Event>();

  onClick(ev: Event) {
    this.next.emit(ev);
  }
}
