import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { PorterService } from '@shared/service/porter/porter.service';

@Component({
  selector: 'rm-modal',
  standalone: true,
  templateUrl: './modal.component.html'
})
export class ModalComponent {
  modalData!: any;

  constructor(private porterService: PorterService){
    this.porterService.showModal$.subscribe((showModal: any) => {
      this.modalData = showModal;
    })
  }

  redirectToDashboard(){
    window.location.replace(environment.salesForceHomeUrl);
  }

  closeModal() {
    this.porterService.closeModal();
  }
}