export class RMUtilities {
  static NON_CRAWLABLE_LINKS = ['/quote-rejected', '/apply', '/error', '/payment'];

  static readonly DISPUTE_TRANSACTION_MAX_DATE_RANGE = 'YYYY-MM-DD';
  static readonly DISPUTE_TRANSACTION_DATE_FORMATTER = 'DD/MM/YYYY';
  static readonly CREATE_LOAN_QUOTE_DELAY = 5000;

  static readonly ClaimAmountTypeOpt = [
    { value: 'FULL_AMOUNT', label: 'Full Amount' },
    { value: 'PARTIAL_AMOUNT', label: 'Partial Amount' }
  ];

  static readonly YesNoOptions = [
    { value: 'YES', label: 'Yes' },
    { value: 'NO', label: 'No' }
  ];
}

export class RMSessionDataKeys {
  static readonly RM_ORGANIC_JOURNEY_DATA = 'rm_organic_journey_data';
  static readonly RM_CREATE_LOAN_APP_ID = 'rm_organic_app_id';
  static readonly RM_PAGE_NOT_FOUND_KEY = 'rm_page_not_found_key';
  static readonly RM_LAST_TRIED_ROUTE = 'rm_last_tried_route';
  static readonly RM_PAYMENT_STATUS_RES = 'rm_payment_status_res';
  static readonly RM_PAYMENT_TOKEN_RES = 'rm_payment_token_res';
  static readonly RM_LOANS_UUID = 'app_uuid';
  static readonly RM_ERROR_DETAILS = 'rm_err_msg';
  static readonly RM_AGGREGATOR = 'rm_aggregator';

  static readonly RM_COOKIE = {
    EXPIRY_DAYS: 180,
    KEY: 'rm_cookie',
    VALUE: 'rm cookie value'
  };
}

export enum CookieCategories {
  Necessary = 'Necessary',
  Functional = 'Functional',
  Performance = 'Performance',
  Analytics = 'Analytics',
  Advertisements = 'Advertisements',
  Other = 'Other'
}

export class PORTER_STATES {
  static readonly LOADER = 'loader';
  static readonly COOKIE_BANNER = 'cookieBanner';
}

export class PAYMENT_STATUS {
  static readonly SUCCESS = 'success';
  static readonly FAILURE = 'fail';
}

export class DATA_STATES {
  static readonly MODULE = 'module';
  static readonly TOKEN = 'token';
}

export class PAYMENT {
  static readonly CUSTOMER_ID_MAP = new Map([
    ['', ''],
    ['A-0013', 'Reevo Money'],
    ['BS-0001', 'My Community Bank'],
    ['C-0002', 'Castle Community Bank']
  ]);

  static readonly REEVO_VALIDATE_PAYMENT_TOKEN_RES_KEY = 'reevo_validate_payment_token';
  static readonly REEVO_AUTH_TOKEN_KEY = 'auth_token';
}
