import { Component, Signal } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';

import { ContainerComponent } from '@shared/components/container/container.component';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { ButterCMSModel } from '@core/models/butter-cms.model';
import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { SignalService } from '@shared/service/signal/signal.service';

@Component({
  selector: 'rm-footer',
  templateUrl: './footer.component.html',
  imports: [NgIf, AsyncPipe, ContainerComponent, SafeHtmlPipe, NgOptimizedImage, NgForOf, RouterLink],
  standalone: true
})
export class FooterComponent {
  collectionData!: Signal<ButterCMSModel.FooterComponentModel>;
  help_url: string = `/${RMRouterLink.Help}`
  legal_url: string = `/${RMRouterLink.Legal}`

  constructor(private signalService: SignalService) {
    this.collectionData = this.signalService.getData(ButterCMSConstants.FOOTER_PAGE_API, 'getCollectionContent');
  }
}
