<ng-container *ngIf="isDisputeFlow || isOnline">
  <ng-content />
  <small *ngIf="isDisputeOffline" class="fixed w-full text-center top-0 py-1 text-white bg-error-600 flex justify-center items-center gap-1">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14px" height="14px" viewBox="0 0 12 12" version="1.1">
      <g id="surface1">
        <path style="fill: none; stroke-width: 2; stroke-linecap: round; stroke-linejoin: round; stroke: rgb(100%, 100%, 100%); stroke-opacity: 1; stroke-miterlimit: 4" d="M 1 1 L 23 23 M 16.71875 11.0625 C 17.539062 11.460938 18.304688 11.960938 19 12.546875 M 5 12.546875 C 6.484375 11.3125 8.265625 10.484375 10.171875 10.15625 M 10.710938 5.046875 C 12.851562 4.875 15.007812 5.140625 17.046875 5.820312 C 19.085938 6.492188 20.96875 7.578125 22.578125 9 M 1.421875 9 C 2.804688 7.773438 4.398438 6.796875 6.117188 6.117188 M 8.53125 16.109375 C 9.546875 15.390625 10.757812 15 12.007812 15 C 13.25 15 14.460938 15.390625 15.476562 16.109375 M 12 20 L 12.007812 20 " transform="matrix(0.5,0,0,0.5,0,0)" />
      </g>
    </svg>
    No internet connection</small
  >
</ng-container>

<rm-container *ngIf="!isDisputeFlow && !isOnline" themeClasses="bg-gradient-radial">
  <div class="grid grid-cols-12 gap-4 lg:gap-8">
    <div class="col-span-12 md:col-span-6 my-auto">
      <h1 class="display-2 mb-6">Oops! No connection detected.</h1>
      <p class="lg-regular py-4">Hey there! It seems your device is off the grid. Take a breather and enjoy this unexpected digital downtime. When ready, try getting back online or refresh this page.</p>
    </div>
    <div class="col-span-12 place-self-center md:col-span-6">
      <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1904_1585)">
          <path d="M5 5L115 115M83.6 55.3C87.6955 57.2988 91.5252 59.8015 95 62.75M25 62.75C32.4104 56.5541 41.3294 52.4311 50.85 50.8M53.55 25.25C64.2621 24.3868 75.0382 25.6908 85.2352 29.0841C95.4323 32.4774 104.841 37.8904 112.9 45M7.1 45C14.0358 38.8693 21.9885 33.9961 30.6 30.6M42.65 80.55C47.726 76.9437 53.7984 75.0063 60.025 75.0063C66.2516 75.0063 72.324 76.9437 77.4 80.55M60 100H60.05" stroke="#391F4C" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_1904_1585">
            <rect width="120" height="120" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</rm-container>
