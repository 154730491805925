import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'rm-container',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './container.component.html'
})
export class ContainerComponent {
  @Input() themeClasses !: string;
}
