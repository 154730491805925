<div class="fixed inset-0 z-50 flex items-center justify-center p-2 sm:p-0 bg-black/20 backdrop-blur-sm backdrop-opacity-100">
  <div class="relative max-w-md bg-white rounded-lg shadow-lg">
    <div class="bg-gray-50 rounded-t-lg px-6 py-4">
      <h4 class="text-primary-700 font-normal">Is your bank not shown?</h4>
    </div>
    <div class="p-6 space-y-4 font-circe-regular text-lg text-gray-600">
      <p>Some banks have still not implemented Open Banking technology for Easy bank transfers.</p>
      <p>If you don't have a bank account that supports Open Banking, you can choose to pay in another way.</p>
      <div class="font-circe-regular">
        <button class="btn primary mb-4 w-full" type="button" (click)="redirectToDashboard()">Use other payment method</button>
        <button class="btn secondary w-full" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
</div>
