<div *ngIf="modalData.state">
  <ng-container [ngSwitch]="modalData.data">
    <ng-container *ngSwitchCase="'cookieBanner'">
      <rm-cookie *ngIf="!excludeBanners"></rm-cookie>
    </ng-container>
    <ng-container *ngSwitchCase="'loader'">
      <rm-loader />
    </ng-container>
    <ng-container *ngSwitchCase="'modal'">
      <rm-modal />
    </ng-container>
  </ng-container>
  <ng-container *ngIf=" !isProd && modalData.data !== 'loader' && !excludeBanners">
    <div class="h-6">
      <p class="fixed w-full text-center bg-accent-ink z-50 font-circe-rounded-regular-400 text-base-white bottom-0" [ngClass]="showBanner ? 'bottom-0' : 'top-0 h-6'">Test Environment</p>
    </div>
  </ng-container>
</div>
