import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { ButterCmsService } from '@core/services/butter-cms/butter-cms.service';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { CookiesService } from '@shared/service/cookie/cookies.service';
import { SeoService } from '@shared/service/seo/seo.service';
import { filter } from 'rxjs';
import { AppService } from './app.service';
import { DATA_STATES, PAYMENT, RMSessionDataKeys } from './utils/rm.utilities';

@Component({
  selector: 'rm-app-root',
  template: `<router-outlet> </router-outlet> <rm-porter />`
})
export class AppComponent {
  moduleWithNoContainer = [RMRouterLink.Payment, RMRouterLink.DisputeTransaction, RMRouterLink.ActivateCard];
  currentUrl!: string;

  needAddQueryParams!: boolean;

  constructor(
    private router: Router,
    private seoService: SeoService,
    private activatedRoute: ActivatedRoute,
    private butterCmsService: ButterCmsService,
    private appService: AppService,
    private cookieService: CookiesService
  ) {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((routeEvent: NavigationEnd) => {
      const routeParams = this.activatedRoute.snapshot.queryParams;
      this.currentUrl = routeEvent.urlAfterRedirects;
      this.needAddQueryParams = this.handleNeedAddQueryParams();

      this.cookieService.cookieAccepted$.subscribe(() => {
        seoService.loadGoogleTagManagerScript(this.currentUrl);
      });

      if (this.needAddQueryParams) {
        if (routeParams[DATA_STATES.TOKEN]) this.appService.paymentToken = routeParams[DATA_STATES.TOKEN];
        else this.appService.paymentToken = this.cookieService.getCookie(PAYMENT.REEVO_AUTH_TOKEN_KEY);

        if (!this.appService.disputeQueryParam)
          this.appService.disputeQueryParam = {
            token: routeParams['token'],
            disputeId: routeParams['disputeId'],
            customerId: routeParams['customerId'],
            transactionAmount: Number(routeParams['transactionAmount'])
          };
      } else {
        if (!this.seoService.seoData) {
          this.butterCmsService.getPageContent(ButterCMSConstants.SEO_METADATA).subscribe((seoData) => {
            this.seoService.setData(seoData);
            this.updateSeoDataFromURL();
          });
        } else {
          this.updateSeoDataFromURL();
        }
      }
    });
  }

  private handleNeedAddQueryParams() {
    return this.moduleWithNoContainer.filter((data) => this.currentUrl.toString().includes(data)).length != 0;
  }

  private updateSeoDataFromURL() {
    this.seoService.updateSeoMetaData(this.currentUrl);
  }
}
