export enum RMRouterLink {
  Home = '',

  // Loans
  Loans = 'loans',
  TellUsAboutYourself = 'apply',
  LoanQuoteRejected = 'quote-rejected',

  // About
  AboutUs = 'about',

  // Help
  Help = 'help',
  LoansHelp = 'loans',
  CreditCardHelp = 'credit-card',
  ContactUs = 'contact-us',

  // Guides
  Guides = 'guides',
  DownloadBankStatement = 'how-to-download-bank-statements',
  HelpGuide = 'how-to-verify-your-identity',

  // legal
  Legal = 'legal',
  Terms = 'legal/website-terms',
  CookiePolicy = 'legal/website-cookie-policy',
  Privacy = 'legal/privacy-policy',
  Complaints = 'legal/complaints',

  // Credit Card
  CreditCard = 'credit-card',
  ActivateCard = 'app/activate-card',
  CreditCardUploadDoc = 'app/upload-document',
  CreditCardLogin = 'app/login',

  //payments
  Payment = 'payment',
  PaymentOpenBanking = 'payment-ob',
  SelectYourBank = 'select-your-bank',
  PaymentRedirect = 'payment-redirect',
  PaymentStatus = 'payment-status',

  // dispute transaction flow
  DisputeTransaction = 'dispute-transaction',
  DisputeProvideMoreDetails = 'provide-more-details',
  DisputeDocumentsUpload = 'documents-upload',
  DisputeNoSupport = 'no-support',
  DisputeContactUs = 'contact-us',
  DisputeSummary = 'dispute-summary',
  DisputeStatus = 'status',

  // Error
  Error = 'error',
  PageNotFound = 'page-not-found',
  SomethingWentWrong = 'something-went-wrong'
}
