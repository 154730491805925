import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ContainerComponent } from '@shared/components/container/container.component';
import { PorterComponent } from '@shared/components/porter/porter.component';
import { InternetStatusComponent } from '@shared/components/internet-status/internet-status.component';

@Component({
  selector: 'rm-payment-content-container',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ContainerComponent, NgOptimizedImage, PorterComponent, InternetStatusComponent],
  templateUrl: './payment-content-container.component.html'
})
export class PaymentContentContainerComponent {

}
