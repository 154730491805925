import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RMSessionDataKeys } from '@app/utils/rm.utilities';
import { AddressHistoryType, Currency } from '@core/enums/rm.enum';
import { BankList, InitiatePayment, PaymentToken, PispData } from '@core/models/payment.model';
import { RmModel } from '@core/models/rm-model';
import { RegexPatternService } from '@core/services/regex/regexPattern.service';
import { environment } from '@env/environment';
import { DobValidator } from '@features/loans/components/find-my-address/dob/validators/dob.validator';
import { JobTitlesModel } from '@features/loans/models/loans.model';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  paymentToken = '';
  paymentTokenRes!: PaymentToken;

  // Query Param
  disputeQueryParam!: RmModel.DisputeQueryParam;

  isBrowserPlatform: boolean = false;
  loanCalculatorValueModel!: any;
  loanCalculatorForm!: FormGroup;

  loanApplicationRes!: RmModel.LoanApplicationResModel;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private formBuilder: FormBuilder,
    private dobValidator: DobValidator,
    private regexService: RegexPatternService,
    private httpClient: HttpClient
  ) {
    this.isBrowserPlatform = isPlatformBrowser(platformId);
    this.buildReevoLoansRegistrationFormGroup();
    this.patchOrganicFormData();
  }

  saveRMSessionData(sessionKey: string, sessionData: Object | string, blacklist: string = ''): void {
    if (this.isBrowserPlatform && blacklist === sessionData) {
      return;
    }

    this.isBrowserPlatform && sessionStorage.setItem(sessionKey, JSON.stringify(sessionData));
  }

  getRmSessionData = (sessionKey: string) => (this.isBrowserPlatform ? JSON.parse(String(sessionStorage.getItem(sessionKey) || '{}')) : {});
  removeRmSessionData = (sessionKey: string) => this.isBrowserPlatform && sessionStorage.removeItem(sessionKey);
  clearAllSessionData = () => this.isBrowserPlatform && sessionStorage.clear();

  /**
   * Get Job title list
   * @returns Job title data
   */
  getJobTitleData = (): Observable<JobTitlesModel> => this.httpClient.get<JobTitlesModel>(environment.apiBaseUrl + environment.jobTitlesAPI);
  private buildReevoLoansRegistrationFormGroup(): void {
    this.loanCalculatorForm = this.formBuilder.group({
      applicationId: new FormControl(''),
      productId: new FormControl(''),

      applicationDetails: this.formBuilder.group({
        user: this.formBuilder.group({
          title: new FormControl(null, [Validators.required]),
          firstName: new FormControl('', {
            validators: [Validators.required, Validators.minLength(2), Validators.maxLength(64)],
            updateOn: 'blur'
          }),
          lastName: new FormControl('', {
            validators: [Validators.required, Validators.minLength(2), Validators.maxLength(64)],
            updateOn: 'blur'
          }),
          email: new FormControl('', {
            validators: [Validators.required, Validators.maxLength(255), Validators.pattern(this.regexService.KeyMaskFormControlValidatorPatterns.email)],
            updateOn: 'blur'
          }),
          phoneNumber: new FormControl('', {
            validators: [Validators.required, Validators.pattern(this.regexService.KeyMaskFormControlValidatorPatterns.phoneNumber)],
            updateOn: 'blur'
          }),
          dateOfBirth: this.formBuilder.group(
            {
              day: new FormControl('', {
                validators: [Validators.required, Validators.pattern(this.regexService.KeyMaskTypes.date)],
                updateOn: 'blur'
              }),
              month: new FormControl('', {
                validators: [Validators.required, Validators.pattern(this.regexService.KeyMaskTypes.month)],
                updateOn: 'blur'
              }),
              year: new FormControl('', {
                validators: [Validators.required, Validators.pattern(this.regexService.KeyMaskTypes.year)],
                updateOn: 'blur'
              })
            },
            {
              validators: [this.dobValidator.customDateValidator]
            }
          ),
          addresses: this.formBuilder.group({
            newAddress: this.buildAddressFormGroup(),
            oldAddress: this.buildAddressFormGroup(true)
          })
        }),
        loan: this.formBuilder.group({
          amount: new FormControl('', [Validators.required]),
          currency: new FormControl(Currency.Gbp),
          termInMonths: new FormControl('', [Validators.required]),
          rentOrMortgage: new FormControl(null, [Validators.required]),

          //Your Finances
          income: new FormControl(null, [Validators.required]),
          incomePeriod: new FormControl(null, [Validators.required]),
          noofdependents: new FormControl('', Validators.required),

          // Loan Purpose
          purpose: new FormControl(null, [Validators.required]),
          purpose_if_other: new FormControl(null),

          // Employment status
          employmentStatus: new FormControl('', [Validators.required]),
          employment_position: new FormControl('', [Validators.required]),
          employer: new FormControl('', [Validators.required])
        })
      })
    });
    this.loanCalculatorForm.valueChanges.subscribe((res) => {
      this.saveRMSessionData(RMSessionDataKeys.RM_ORGANIC_JOURNEY_DATA, res);
    });
  }

  private buildAddressFormGroup(isSecondAddress = false): FormGroup {
    const baseFrmGrp: FormGroup<any> = this.formBuilder.group({
      addressType: new FormControl(isSecondAddress ? AddressHistoryType.Old : AddressHistoryType.Current),
      abodeNumber: new FormControl('', { validators: [], updateOn: 'blur' }),
      buildingNumber: new FormControl('', { validators: [], updateOn: 'blur' }),
      buildingName: new FormControl('', { validators: [], updateOn: 'blur' }),
      street: new FormControl('', { validators: [], updateOn: 'blur' }),
      city: new FormControl('', { validators: [], updateOn: 'blur' }),
      county: new FormControl('', { validators: [], updateOn: 'blur' }),
      country: new FormControl('', { validators: [], updateOn: 'blur' }),

      post_code: new FormControl('', { validators: [], updateOn: 'blur' }),

      from: new FormControl(''),

      addressFormat: new FormControl(null)
    });

    if (baseFrmGrp) {
      if (isSecondAddress) {
        baseFrmGrp.addControl('outsideUk', new FormControl(null));
      } else {
        baseFrmGrp.addControl('residential_status', new FormControl('', [Validators.required]));
      }
    }

    return baseFrmGrp;
  }

  createLoanApplication(payload: RmModel.LoanApplicationPayloadModel): Observable<RmModel.LoanQuoteResModel> {
    return this.httpClient.post(environment.apiBaseUrlV2 + environment.createLoanApplicationAPI, payload).pipe(
      map((res) => res as RmModel.LoanQuoteResModel),
      catchError(this.errorHandler)
    );
  }

  private patchOrganicFormData() {
    const savedData = this.getRmSessionData(RMSessionDataKeys.RM_ORGANIC_JOURNEY_DATA);
    if (Object.keys(savedData).length !== 0) {
      this.loanCalculatorForm.setValue(savedData);
    }
  }

  validatePaymentTokenApi(data: { token: string }): Observable<PaymentToken> {
    return this.httpClient.post(environment.apiBaseUrl + environment.validatePaymentTokenAPI, data).pipe(
      map((response) => response as PaymentToken),
      catchError(this.errorHandler)
    );
  }

  initiatePaymentApi(data: InitiatePayment): Observable<PispData> {
    return this.httpClient.post(environment.apiBaseUrl + environment.initiatePayment, data).pipe(
      map((response: any) => response.pispData as PispData),
      catchError(this.errorHandler)
    );
  }

  getApi(url: string): Observable<BankList | any> {
    return this.httpClient.get(environment.apiBaseUrl + url).pipe(
      map((res) => res),
      catchError(this.errorHandler)
    );
  }

  private errorHandler = (error: HttpErrorResponse) => throwError(() => new Error(`${error} - Error in service`));
}
