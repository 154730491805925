<div #container>
  <ng-container *ngIf="notifications.length > 0">
    <div class="relative mr-6 lg:mr-8">
      <svg class="cursor-pointer" (click)="toggleModal()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z" stroke="#391F4C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="17" cy="4" r="4" fill="#F8D8FA"/>
      </svg>

      <div [ngClass]="isOpen ? '' : 'hidden'">
        <svg class="absolute top-7 lg:top-7 inset-x-1" width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 0L16 8H0L8 0Z" fill="#FCFCFD"/>
        </svg>
        
        <div class="absolute top-9 lg:top-9 -right-12 lg:-right-16 bg-gray-25 opacity-95 w-[16.5rem] max-w-[16.5rem] px-2 pb-2 rounded max-h-[80vh] overflow-y-auto">
          <div class="flex flex-col">
            <ng-container *ngFor="let notification of notifications">
              <div class="border-b border-gray-200 last:border-none py-2">
                <p class="xs-regular mb-1 text-accent-ink">{{ notification.start_date | date: 'dd/MM/yy' }}</p>
                <div class="sm-bold break-words text-accent-ink">{{ notification.description }}</div>
              </div>
            </ng-container>
          </div>

          <div class="inline-flex gap-2 items-center cursor-pointer" (click)="toggleModal()">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.73303 5.00006L8.84814 1.88486C9.05087 1.68223 9.05087 1.3546 8.84814 1.15197C8.64551 0.949342 8.31788 0.949342 8.11525 1.15197L5.00005 4.26717L1.88493 1.15197C1.68221 0.949342 1.35468 0.949342 1.15204 1.15197C0.949318 1.3546 0.949318 1.68223 1.15204 1.88486L4.26716 5.00006L1.15204 8.11526C0.949318 8.31789 0.949318 8.64551 1.15204 8.84815C1.25303 8.94922 1.38581 9 1.51849 9C1.65117 9 1.78386 8.94922 1.88493 8.84815L5.00005 5.73295L8.11525 8.84815C8.21633 8.94922 8.34901 9 8.4817 9C8.61438 9 8.74706 8.94922 8.84814 8.84815C9.05087 8.64551 9.05087 8.31789 8.84814 8.11526L5.73303 5.00006Z" fill="#147F8E" stroke="#147F8E" stroke-width="0.5"/>
            </svg>            
            <p class="sm-bold text-primary-600">Close</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>