import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { map, Observable } from 'rxjs';

import { DisputeHeadingAPIKeyMetaDataModel, DisputeMoreDetailsMetaDataModel, DisputeTransactionMetaDataModel, QuestionnairePayloadModel } from '@app/dispute/models/dispute.models';
import { DisputeService } from '@app/dispute/service/dispute.service';
import { CheckBoxComponent } from '@features/loans/components/check-box/check-box.component';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { DisputeNextBtnComponent } from '../dispute-next-btn/dispute-next-btn.component';

@Component({
  selector: 'rm-dispute-transaction',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, CheckBoxComponent, DisputeNextBtnComponent],
  templateUrl: './dispute-transaction.component.html'
})
export class DisputeTransactionComponent implements OnInit {
  private disputeMetaDataModel!: DisputeTransactionMetaDataModel;
  disputeMetaData$!: Observable<DisputeTransactionMetaDataModel>;

  // Multi select options
  selectedMultiOptions: Array<DisputeTransactionMetaDataModel | DisputeHeadingAPIKeyMetaDataModel> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public disputeService: DisputeService
  ) {
    this.disputeMetaDataModel = this.disputeService.disputeTransactionMetaData[0];
  }

  ngOnInit(): void {
    this.disputeMetaData$ = this.activatedRoute.url.pipe(
      map((routPath) => {
        // reset
        if (!routPath || routPath.length === 0) {
          this.selectedMultiOptions = [];
          this.disputeService.resetMoreDetailsMetaData();
        }

        const routeObj = routPath[0] as Route;
        if (routeObj?.path) {
          const disputeRouteObj = this.disputeService.disputeRouteUrlDetails.get(routeObj.path) as DisputeTransactionMetaDataModel;
          this.disputeMetaDataModel = disputeRouteObj?.multiSelectOptions && disputeRouteObj.multiSelectOptions.length > 0 ? disputeRouteObj : disputeRouteObj.options[0];

          if (this.disputeMetaDataModel.multiSelectOptions) {
            Array.from(this.disputeService.selectedDisputeCategory)
              .slice(2)
              .forEach((category) => {
                category[1].answers.forEach((answer) => {
                  this.selectedMultiOptions.push({
                    apiKey: answer.key,
                    heading: answer.value
                  });
                });
              });
          } else {
            this.disputeService.selectedDisputeCategory.delete(Array.from(this.disputeService.selectedDisputeCategory.keys())[2]);
            this.disputeService.resetMoreDetailsMetaData();
            this.disputeService.disputeDocuments.clear();
          }

          return this.disputeMetaDataModel;

          // return this.getCurrentScreenDisputeMetaData(this.disputeService.disputeTransactionMetaData, routeObj?.path);
        }

        return this.disputeService.disputeTransactionMetaData[0];
      })
    );
  }
  
  onNavigateToContactUs(): void {
    this.router.navigate([`/${RMRouterLink.DisputeTransaction}/${RMRouterLink.DisputeContactUs}`]);
  }

  onNavigateToNoSupport(): void {
    this.router.navigate([`/${RMRouterLink.DisputeTransaction}/${RMRouterLink.DisputeNoSupport}`]);
  }

  onNavigateToSubPage(selectedItem: DisputeTransactionMetaDataModel): void {
    // update the selected dispute charge reason category
    const qaDisputeModel: QuestionnairePayloadModel = {
      question: {
        key: this.disputeMetaDataModel.apiKey,
        title: this.disputeMetaDataModel.heading
      },
      answers: [
        {
          key: selectedItem.apiKey,
          value: selectedItem.heading,
          dataType: 'string'
        }
      ]
    };
    this.disputeService.selectedDisputeCategory.set(this.disputeMetaDataModel.heading, qaDisputeModel);

    // route to sub-page
    if (!selectedItem.routeUrl) {
      this.onNavigateToContactUs();

      return;
    }

    this.router.navigate([`/${RMRouterLink.DisputeTransaction}/${selectedItem?.routeUrl}`]);
  }

  onMultiOptionHandler(eventData: any, selectedItem: DisputeHeadingAPIKeyMetaDataModel): void {
    this.addRemoveMultiOptions(eventData?.target?.checked, selectedItem);
  }

  onNextScreen(selectedItem: DisputeTransactionMetaDataModel): void {
    this.disputeService.questionsProvideMoreDetails = selectedItem.moreDetails as DisputeMoreDetailsMetaDataModel;

    // update the selected multi option in service
    const qaDisputeModel: QuestionnairePayloadModel = {
      question: {
        key: this.disputeMetaDataModel.apiKey,
        title: this.disputeMetaDataModel.heading
      },
      answers: [
        ...this.selectedMultiOptions.map(({ heading, apiKey }) => ({
          key: apiKey,
          value: heading,
          dataType: 'string'
        }))
      ]
    };
    this.disputeService.selectedDisputeCategory.set(this.disputeMetaDataModel.heading, qaDisputeModel);

    this.router.navigate([`/${RMRouterLink.DisputeTransaction}/${RMRouterLink.DisputeProvideMoreDetails}`]);
  }

  /** Add or  Remove -> Multi select options */
  private addRemoveMultiOptions(addFlag: boolean, selectedItem: DisputeTransactionMetaDataModel | DisputeHeadingAPIKeyMetaDataModel): void {
    if (addFlag) {
      this.selectedMultiOptions.push(selectedItem);
    } else {
      const index = this.selectedMultiOptions.findIndex(({ heading }) => heading === selectedItem.heading);

      if (index !== -1 && this.selectedMultiOptions.length > 0) {
        this.selectedMultiOptions.splice(index, 1);
      }
    }
  }

  isChecked(apiKey: string) {
    return this.selectedMultiOptions.some((opt) => opt.apiKey === apiKey);
  }
}
