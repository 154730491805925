import { CommonModule, Location, NgOptimizedImage } from '@angular/common';
import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';

import { environment } from '@env/environment';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { ContainerComponent } from '@shared/components/container/container.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { DisputeTransactionComponent } from './components/dispute-transaction/dispute-transaction.component';
import { DisputeSFIntegrationMessageEventModel, MobileAppEvents } from './models/dispute.models';
import { DisputeService } from './service/dispute.service';
import { InternetStatusComponent } from '@shared/components/internet-status/internet-status.component';

const COMPONENTS = [ContainerComponent, LoaderComponent, InternetStatusComponent];

@Component({
  selector: 'rm-dispute',
  standalone: true,
  imports: [CommonModule, RouterModule, NgOptimizedImage, ...COMPONENTS],
  templateUrl: './dispute.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DisputeComponent {
  isDisputeMetaDataLoaded = false;
  parentSourceOrigin = environment.disputeSFPostMessageOrigin;
  disputeSFIntegrationEventModel!: DisputeSFIntegrationMessageEventModel;

  constructor(
    private router: Router,
    private locationHistory: Location,
    private disputeService: DisputeService
  ) {
    this.disputeService.getDisputeMetaDataCollection().subscribe((data) => {
      this.disputeService.disputeTransactionMetaData = data;

      const routesStr = this.disputeService.generateRoutes(this.disputeService.disputeTransactionMetaData);
      const disputeRoutes: Routes = routesStr.map((p: any) => ({ path: p, component: DisputeTransactionComponent }));

      // REFACTOR the ROUTE URL...
      const mainRouter: any = this.router.config[0];

      const disputeChildRoutes = mainRouter._loadedRoutes;
      disputeChildRoutes.push(...disputeRoutes);

      // this.router.resetConfig(updatedRoutes);

      this.isDisputeMetaDataLoaded = true;
    });
  }

  onBackNav(): void {
    if (this.locationHistory.path().split('?')[0] === `/${RMRouterLink.DisputeTransaction}`) {
      this.disputeService.sendDataToReactNativeApp(MobileAppEvents.BackToTransaction);
    } else {
      this.locationHistory.back();
    }
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    // Allow this origin to communicate
    if (event.origin === this.parentSourceOrigin) {
      // get parent source to send message
      if (!this.disputeService.windowSource) this.disputeService.windowSource = event.source as MessageEventSource;
      this.disputeSFIntegrationEventModel = event.data as DisputeSFIntegrationMessageEventModel;

      console.log('Child received data : -> ', this.disputeSFIntegrationEventModel);
    }
  }

  isSubmitPage(): boolean {
    return this.router.url === `/${RMRouterLink.DisputeTransaction}/${RMRouterLink.DisputeStatus}`;
  }
}
