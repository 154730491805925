import { RMRouterLink } from "@routing/rm-routing.enum";

const baseUrl = "https://www.reevomoney.com/";
const loansUrl = baseUrl + RMRouterLink.Loans;
const creditCardsUrl = baseUrl + RMRouterLink.CreditCard;

export namespace Schema {
  export const loanSchema = {
    "@context": "https://schema.org/",
    "@type": "LoanOrCredit",
    name: "Loan",
    image: "https://cdn.buttercms.com/OruOQKc1SVakPXJLp5DY",
    description: "Get a personalised quote in 3 minutes. No impact on your credit score.",
    loanType: "Unsecured Personal Loan",
    url: loansUrl,
    amount: {
      "@type": "MonetaryAmount",
      minValue: "1000",
      maxValue: "15000",
      currency: "GBP"
    },
    currency: "GBP",
    loanTerm: {
      "@type": "QuantitativeValue",
      minValue: "12",
      maxValue: "60",
      unitCode: "MON"
    },
    review: [
      {
        "@type": "Review",
        reviewBody: "Very easy application and fast response, money in my account less than 24,hours later. Would definitely recommend this company.",
        url: "https://uk.trustpilot.com/reviews/63fcb098167173609ed841e4",
        itemReviewed: {
          "@type": "LoanOrCredit",
          name: "Loan"
        },
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5"
        },
        author: {
          "@type": "Person",
          givenName: "Ms Nicola Foster"
        }
      },
      {
        "@type": "Review",
        reviewBody: "Excellent service, no problems at all. Will recommend highly",
        url: "https://uk.trustpilot.com/reviews/63fa693bb44ecdb678e012ff",
        itemReviewed: {
          "@type": "LoanOrCredit",
          name: "Loan"
        },
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5"
        },
        author: {
          "@type": "Person",
          givenName: "Nicholas Leith"
        }
      },
      {
        "@type": "Review",
        reviewBody: "100% very fast within 24hrs decision is made",
        url: "https://uk.trustpilot.com/reviews/63f6b5cd6f3134764b71c3ea",
        itemReviewed: {
          "@type": "LoanOrCredit",
          name: "Loan"
        },
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5"
        },
        author: {
          "@type": "Person",
          givenName: "Ms A Harris"
        }
      }
    ]
  }

  const creditCardSchema = {
    "@context": "https://schema.org/",
    "@type": "LoanOrCredit",
    name: "Credit Card",
    image: "https://cdn.buttercms.com/OruOQKc1SVakPXJLp5DY",
    description: "A card to control your expenses, build your credit score and never miss a payment.",
    loanType: "Credit Card",
    url: creditCardsUrl,
    currency: "GBP",
    review: [
      {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5"
        },
        author: {
          "@type": "Organization",
          name: "Reevo Money",
          url: baseUrl
        }
      }
    ]
  }

  export const loanSchemaId = 'seoLoanId';
  export const creditCardSchemaId = 'seoCreditCardId';
  export const faqSchemaId = 'seoFaqEntityId';

  export const dynamicSchemaMap: SchemaMap[] = [
    {
      route: RMRouterLink.Home,
      schemas: [
        { id: loanSchemaId, schema: loanSchema },
        { id: creditCardSchemaId, schema: creditCardSchema },
        { id: faqSchemaId, schema: {} }
      ]
    },
    {
      route: RMRouterLink.Loans,
      schemas: [
        { id: loanSchemaId, schema: loanSchema }
      ]
    },
    {
      route: RMRouterLink.CreditCard,
      schemas: [
        { id: creditCardSchemaId, schema: creditCardSchema }
      ]
    },
    {
      route: RMRouterLink.Help,
      schemas: [
        { id: faqSchemaId, schema: {} }
      ]
    }
  ];

  export interface QuestionSchema {
    '@type': string;
    name: string;
    acceptedAnswer: {
      '@type': string;
      text: string;
    };
  }

  export interface Schema {
    id: string;
    schema: any;
  }

  interface SchemaMap {
    route: RMRouterLink;
    schemas: Schema[]
  }
}