import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookiesService } from '../cookie/cookies.service';
import { Observable, ReplaySubject, of } from 'rxjs';
import { CookieCategories } from '@app/utils/rm.utilities';
import { ExternalScriptType } from '@core/models/rm-model';

declare global {
  interface Window {
    ga: any;
    dataLayer?: any;
  }
}

interface ScriptLoader {
  [url: string]: ReplaySubject<void>;
}

@Injectable({
  providedIn: 'root'
})
export class ExternalScriptLoaderService {
  scriptLoader: ScriptLoader = {};

  isBrowserPlatform = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private readonly document: Document,
    private cookiesService: CookiesService
  ) {
    this.isBrowserPlatform = isPlatformBrowser(this.platformId);
  }

  getExternalScriptInstance(scriptUrl: string, externalScriptType?: ExternalScriptType): Observable<void> {
    if (this.scriptLoader[scriptUrl]) {
      return this.scriptLoader[scriptUrl].asObservable();
    }

    let shouldLoadScript = false;

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;

    if (this.isBrowserPlatform) {
      switch (externalScriptType) {
        case ExternalScriptType.GoogleAnalyticsType:
          if (this.cookiesService.cookiePreferences[CookieCategories.Analytics]) {

            script.src = scriptUrl;
            // Google Tag Manager
            window.dataLayer = window.dataLayer || [];
            function gtag(str: string, ...args: any) {
              window.dataLayer.push(str, ...args, arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-2JRBEQMWJ0', {send_page_view: false});
            shouldLoadScript = true;
          }
          break;

        case ExternalScriptType.GoogleTagManagerType:
          if (this.cookiesService.cookiePreferences[CookieCategories.Analytics]) {
            // If Google Tag Manager script is provided as text
            script.text = scriptUrl;
            shouldLoadScript = true;
          }
          break;

        default:
          break;
      }
    }

    if (shouldLoadScript) {
      this.scriptLoader[scriptUrl] = new ReplaySubject();
      script.onload = () => {
        this.scriptLoader[scriptUrl].next();
        this.scriptLoader[scriptUrl].complete();
      };

      this.document.body.appendChild(script);

      return this.scriptLoader[scriptUrl].asObservable();
    }

    return of();
  }
}
