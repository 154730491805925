<div class="bg-gray-50 py-4 px-6 rounded flex flex-col gap-4">
    <div class="flex gap-4 items-center">
      <div class="flex gap-3 sm:gap-4 w-full items-center cursor-pointer" (click)="toggleDropdown()">
        <img src="../../../../../assets/logos/chevron-down.svg" width="24" height="24" alt="dropdown" [ngClass]="isOpen ? 'rotate-180' : ''" />
        <p class="sm-bold sm:md-bold text-gray-900 w-full">{{ cookieType.category }}</p>
      </div>
      <div class="flex gap-2 sm:gap-3 items-center">
        <p class="xs-regular sm:sm-regular text-gray-700 whitespace-nowrap">{{ cookieType.always_enabled ? 'Always enabled' : isEnabled ? 'Enabled' : 'Disabled' }}</p>
        <ng-container *ngIf="!cookieType.always_enabled">
          <div class="relative w-11 h-6 py-[0.0625rem] rounded-full cursor-pointer border" [ngClass]="isEnabled ? 'bg-primary-600 border-gray-700' : 'bg-gray-300 border-gray-400'" (click)="toggleCookie()">
            <div class="absolute w-5 h-5 rounded-full bg-white shadow-toggle transition-all" [ngClass]="isEnabled ? 'bg-white left-[1.3125rem]' : 'bg-gray-200 left-[0.0625rem]'"></div>
          </div>
        </ng-container>
      </div>
    </div>
    <div [ngClass]="isOpen ? '' : 'hidden'" [innerHTML]="cookieType.description"></div>
  </div>