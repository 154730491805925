import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Signal, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { ButterCMSModel } from '@core/models/butter-cms.model';
import { RMUtilities } from '../../../utils/rm.utilities';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { Schema } from './seo.models';
import { ButterCmsService } from '@core/services/butter-cms/butter-cms.service';
import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { SignalService } from '../signal/signal.service';
import { ExternalScriptLoaderService } from '../external-script-loader/external-script-loader.service';
import { environment } from '@env/environment';
import { ExternalScriptType } from '@core/models/rm-model';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  seoData!: ButterCMSModel.SeoData;
  currentRoute?: string;
  helpPageModel!: Signal<ButterCMSModel.HelpPageModel>

  dynamicSeoSchemaIds: string[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
    private butterCmsService: ButterCmsService,
    private signalService: SignalService,
    private externalScriptLoaderService: ExternalScriptLoaderService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.helpPageModel = this.signalService.getData(ButterCMSConstants.HELP_PAGE_API, 'getPageContent');
  }

  setData(data: ButterCMSModel.SeoData): void {
    this.seoData = data;
  }

  updateSeoMetaData(url: string): void {
    this.setSeoTags(url);
    this.setSeoSchemas(url);
  }

  private setSeoTags(url: string) {
    const foundItem = this.seoData?.fields.seo_data.find((item: ButterCMSModel.SeoRepeater) => {
      return item.route === url;
    });

    if (foundItem) {
      const { title, description } = foundItem;
      this.setAndUpdateSEOTags(title, description);
    }

    // Set noindex tag for noncrawlable links
    if (this.meta.getTag('name="robots"')) this.meta.removeTag('name="robots"');

    RMUtilities.NON_CRAWLABLE_LINKS.forEach((link) => {
      if (url.includes(link) && !this.meta.getTag('name="robots"')) this.meta.addTag({ name: 'robots', content: 'noindex' });
    });

    this.setCanonical();
  }

  // Set SEO Tags
  setAndUpdateSEOTags(seo_title: string, seo_description?: string) {
    this.meta.updateTag({
      property: 'og:site_name',
      content: 'Reevo Money'
    });
    this.meta.updateTag({ property: 'og:title', content: seo_title });
    seo_description && this.meta.updateTag({ name: 'description', content: seo_description });

    // Set regular title and description meta tags
    this.titleService.setTitle(seo_title);
  }

  private setCanonical(url?: string) {
    const canURL = (url == undefined ? this.document.URL : url).split('?')[0];
    const existingLink = this.document.querySelector('link[rel="canonical"]') as HTMLLinkElement;
    if (existingLink) {
      existingLink.setAttribute('href', canURL);
      this.meta.updateTag({ property: 'og:url', content: canURL });
    } else {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canURL);
      this.document.head.appendChild(link);
      this.meta.updateTag({ property: 'og:url', content: canURL });
    }
  }

  private setSeoSchemas(url: string) {
    this.dynamicSeoSchemaIds.forEach((id: string) => this.removeSeoSchemaScriptTag(id));

    const parentRoute = url.toLowerCase().split('/')[1];
    const schemas = Schema.dynamicSchemaMap.find((obj) => obj.route === parentRoute)?.schemas;

    schemas &&
      schemas.forEach((schemaObj) => {
        switch (schemaObj.id) {
          case Schema.loanSchemaId:
            this.setLoanSchemaData(schemaObj);
            break;
          case Schema.faqSchemaId:
            this.setFaqSchemaData(url);
            break;
          default:
            this.setDynamicSchema(schemaObj);
            break;
        }
      });
  }

  private setDynamicSchema(schemaObj: Schema.Schema) {
    this.dynamicSeoSchemaIds.push(schemaObj.id);
    this.removeSeoSchemaScriptTag(schemaObj.id);
    this.addScriptTag(schemaObj.id, schemaObj.schema);
  }

  private addSeoFAQsSchema(url: string, faqsSchema: any): void {
    let mainEntity = [];
    const routes = url.split('/');
    const childRoutes = routes.slice(2);
    if (childRoutes.length == 0) mainEntity = faqsSchema[RMRouterLink.LoansHelp].concat(faqsSchema[RMRouterLink.CreditCardHelp]);
    else mainEntity = faqsSchema[childRoutes[childRoutes.length - 1]];

    const FAQEntitySeoSchemaJSON = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      '@id': 'https://www.reevomoney.com/help/',
      mainEntity: mainEntity
    };

    this.setDynamicSchema({ id: Schema.faqSchemaId, schema: FAQEntitySeoSchemaJSON });
  }

  private faqToQuestionSchema(faqRepeater: ButterCMSModel.FaqModel[]): Schema.QuestionSchema[] {
    return faqRepeater.slice(0, 3).flatMap((faq) => {
      return faq.fields.questions.slice(0, 1).map((question) => ({
        '@type': 'Question',
        name: question.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: question.answer
        }
      }));
    });
  }

  private setFaqSchemaData(url: string) {
    if(this.helpPageModel()){
      const faqsSchema = {
        [RMRouterLink.LoansHelp]: this.faqToQuestionSchema(this.helpPageModel().fields.loan_faq_repeater),
        [RMRouterLink.CreditCardHelp]: this.faqToQuestionSchema(this.helpPageModel().fields.credit_card_faq_repeater)
      };
      this.addSeoFAQsSchema(url,faqsSchema);
    }
  }

  private setLoanSchemaData(loanSchemaObj: Schema.Schema) {
    this.butterCmsService.getCollectionContent(ButterCMSConstants.RM_LOAN_CALCULATOR_FORMULA_DATA).subscribe(res => {
      const calculatorFormula = res.rm_loan_calculator_formula[0] as ButterCMSModel.LoanCalculatorModel;
      loanSchemaObj.schema.amount.minValue = calculatorFormula.min_borrow;
      loanSchemaObj.schema.amount.maxValue = calculatorFormula.max_borrow;
      loanSchemaObj.schema.loanTerm.minValue = calculatorFormula.min_months;
      loanSchemaObj.schema.loanTerm.maxValue = calculatorFormula.max_months;
      this.setDynamicSchema(loanSchemaObj);
    });
  }

  private removeSeoSchemaScriptTag(seoSchemaId: string): void {
    const existingScriptElement = this.document.getElementById(seoSchemaId);
    existingScriptElement && existingScriptElement.remove();
  }

  private addScriptTag(scriptId: string, seoSchema: unknown, shouldDefer = true): HTMLScriptElement {
    const scriptElement = this.document.createElement('script');
    scriptElement.setAttribute('id', scriptId);
    scriptElement.type = 'application/ld+json';
    scriptElement.defer = shouldDefer;
    scriptElement.text = JSON.stringify(seoSchema);
    this.document.body.appendChild(scriptElement);

    return scriptElement;
  }

  // Google Tag 
  loadGoogleTagManagerScript(routeUrl: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const args = {
        page_path: routeUrl,
        page_title: document.title,
        page_location: document.location.href
      };

      this.externalScriptLoaderService.getExternalScriptInstance(environment.googleTagManagerScript, ExternalScriptType.GoogleTagManagerType);
      this.externalScriptLoaderService.getExternalScriptInstance(environment.googleTagManagerUrl, ExternalScriptType.GoogleAnalyticsType);
      this.googleAnalyticsService.triggerPageViewEvent(args);
    }
  }
}
