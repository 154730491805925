import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegexPatternService {
  KeyMaskTypes = {
    all: /[^\s\S\d\D]/g,

    alphaNumeric: /[^a-z0-9\s]/gi,
    alphaCustomSymbols: /[^a-zA-Z-'" \s]/gi,

    positiveInteger: /[^\d]/g,

    phoneNumber: /[^\d\+]/g,
    // DOB
    date: /^(([0][1-9]|[1-9])|[12]\d|3[01])$/,
    month: /^(([0][1-9]|[1-9])|1[0-2])$/,
    year: /^\d{4}$/
  };

  KeyMaskFormControlValidatorPatterns = {
    phoneNumber: /^(07\d{9}|7\d{9})$/,

    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    postcode: /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/
  };
}
