<ng-container *ngIf="isPaymentModule">
  <header class="border-b border-gray-300">
    <rm-container themeClasses="p-4">
      <img alt="Reevo Money logo" width="96" height="44" ngSrc="https://cdn.buttercms.com/LG2hO59hRVOgoRANcYuj"
        priority />
    </rm-container>
  </header>
</ng-container>

<ng-container *ngIf="menuItems && !isPaymentModule">
  <header
    class="z-30 border-b lg:border-0 border-accent-ink/30 bg-gradient-radial fixed lg:relative left-0 right-0 bottom-auto lg:left-auto lg:right-auto py-2 md:py-4 xl:py-6 px-4 md:px-8 xl:px-10" [ngClass]="envBannerPositionBottom ? 'top-0 lg:top-auto' : 'top-6'">
    <nav class="grid grid-cols-12">
      <div class="col-span-10 lg:col-span-3">
        <img (click)="showHamburger = false" alt="Reevo Money logo" class="h-8 sm:h-auto w-8 md:w-auto cursor-pointer"
          height="44" width="44" ngSrc="https://cdn.buttercms.com/Mfn9bzzYRlOHlfUuaR0f" priority routerLink="/" />
      </div>
      <div class="col-span-2 my-auto place-self-end lg:col-span-9 z-50">
        <ul class="hidden md:space-x-4 lg:space-x-8 lg:flex items-center">
          <ng-container *ngFor="let menuItem of menuItems">
            <!--  No Submenu means normal links of navbar-->
            <li>
              <ng-container *ngIf="menuItem.label !== signInMenuName">
                <a [routerLink]="menuItem.url"
                  class="h6 text-accent-ink hover:decoration-accent-paper decoration-[0.188rem] underline-offset-8 hover:underline"
                  routerLinkActive="underline hover:decoration-accent-ink">
                  {{ menuItem.label }}
                </a>
              </ng-container>
              <!-- Sign in button link -->
              <ng-container *ngIf="menuItem.label === signInMenuName">
                <div class="flex items-center">
                  <rm-notification class="hidden lg:flex" />
                  <button type="button" (click)="goToLink(menuItem.label, menuItem.url)"
                    class="btn transparent md:lg-regular md-regular w-auto sm:w-fit md:p-1 lg:px-6 lg:py-2">{{
                    menuItem.label }}</button>
                </div>
              </ng-container>
            </li>
          </ng-container>
        </ul>

        <!-- Mobile hamburger menu icon -->
        <div class="flex items-center">
          <rm-notification class="lg:hidden" />
          <div (click)="toggleHamburger()" class="lg:hidden cursor-pointer h-2.5 mt-0.5 my-auto">
            <div class="w-6 relative mx-auto transition-all duration-500 ease-in-out rotate-0 lg:hidden">
              <div [ngClass]="showHamburger ? 'rotate-[135deg] top-1.5' : 'rotate-0 top-0'"
                class="block absolute h-0.5 w-full bg-accent-ink transition-all duration-700 ease-in-out"></div>
              <div [ngClass]="showHamburger ? 'hidden' : 'rotate-0 top-1.5'"
                class="block absolute h-0.5 w-full bg-accent-ink transition-all duration-700 ease-in-out"></div>
              <div [ngClass]="showHamburger ? '-rotate-[135deg] top-1.5' : 'rotate-0 top-3'"
                class="block absolute h-0.5 w-full bg-accent-ink transition-all duration-700 ease-in-out"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="z-10 col-span-12">
        <div class="flex w-full justify-center lg:hidden">
          <div [ngClass]="showHamburger ? 'active right-0 top-[3.07rem] md:top-[5rem] block' : '-right-[200%]'"
            class="absolute z-50 w-full transition-all duration-500" id="mobile-menu">
            <!-- add hidden here later -->
            <ul class="h-screen bg-gradient-radial">
              <ng-container *ngFor="let menuItem of menuItems">
                <li
                  class="py-4 hover:decoration-accent-paper decoration-[0.188rem] underline-offset-8 hover:underline text-center">
                  <ng-container *ngIf="menuItem.label !== signInMenuName">
                    <a class="h6 block pl-3 text-accent-ink" [routerLink]="menuItem.url"
                      routerLinkActive="underline decoration-accent-ink" (click)="toggleHamburger()">
                      {{ menuItem.label }}
                    </a>
                  </ng-container>

                  <!-- Sign in button link -->
                  <ng-container *ngIf="menuItem.label === signInMenuName">
                    <button type="button" class="btn transparent md:lg-regular md-regular ml-3 w-fit"
                      (click)="goToLink(menuItem.label, menuItem.url)">{{ menuItem.label }}</button>
                  </ng-container>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</ng-container>