import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';

import { AppService } from '@app/app.service';
import { RMSessionDataKeys } from '@app/utils/rm.utilities';
import { FooterComponent } from '@core/components/footer/footer.component';
import { HeaderComponent } from '@core/components/header/header.component';
import { AggregatorsTypes } from '@core/enums/rm.enum';
import { ButterCMSModel } from '@core/models/butter-cms.model';
import { environment } from '@env/environment';
import { RMRouterLink } from '@routing/rm-routing.enum';
import { InternetStatusComponent } from '@shared/components/internet-status/internet-status.component';
import { PorterComponent } from '@shared/components/porter/porter.component';
import { LoadApiOnScrollDirective } from '@shared/directives/load-api-on-scroll/load-api-on-scroll.directive';
import { PorterService } from '@shared/service/porter/porter.service';

@Component({
  selector: 'rm-content-container',
  standalone: true,
  imports: [CommonModule, RouterOutlet, PorterComponent, HeaderComponent, FooterComponent, LoadApiOnScrollDirective, InternetStatusComponent],
  templateUrl: './content-container.component.html'
})
export class ContentContainerComponent {
  seoData$!: Observable<ButterCMSModel.SeoData>;
  moduleName = RMRouterLink.Home;

  hamburgerStatus = false;
  isFixedTermRoute = false;
  isPaymentModule = false;
  isHeaderMainContentLoaded = false;
  currentUrl!: string;
  isBrowserPlatform!: boolean;
  envBannerPositionBottom = environment.production;

  private appService = inject(AppService);
  private porterService = inject(PorterService);

  constructor() {
    const aggregator = this.getAggregatorType();
    this.appService.saveRMSessionData(RMSessionDataKeys.RM_AGGREGATOR, aggregator);

    this.porterService.openModal('ForTestBanner');
    this.porterService.showModal$.subscribe((res) => {
      this.envBannerPositionBottom = res.headerPosition;
    });
  }

  getAggregatorType(): string {
    const referrer = document.referrer.toLowerCase();
    const searchEngines = ['google', 'yahoo', 'bing', 'duckduckgo', 'ecosia'];

    const isSearchEngine = searchEngines.some((engine: string) => referrer.includes(engine));

    return isSearchEngine ? AggregatorsTypes.SearchEngine : AggregatorsTypes.Direct;
  }
}
